import React, { useState, useEffect, useRef } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import MaskedInput from "antd-mask-input";
import {
	Modal,
	Button,
	Col,
	Row,
	Layout,
	Form,
	Input,
	InputNumber,
	Select,
	Alert,
	DatePicker,
	Upload,
	Radio,
	message,
	notification
} from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";
import Swal from "sweetalert2";
import { UploadOutlined } from "@ant-design/icons";
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;


function ModalView({ visible, refetch, close, data, selectedDate}) {

	const didMount = useRef(false);
	const [state, setState] = useState({});

	const [
		getClients,
		{ loading: loadingClients, data: dataClients },
	] = useLazyQuery(Queries.GET_CLIENTS, {
		fetchPolicy: 'no-cache'
	});

	const loadingSave = false;

	const onClose = () => {
		setState(null);
		close();
	};

	const ErrorDialog = () => {
		
	}

	const save = () => {
		if(!state.date)
			return ErrorDialog('Por favor, selecione uma data.');
	}

	useEffect(() => didMount.current = true, []);

	useEffect(() => {
		if (visible && didMount.current) {

			getClients();

			let obj = {};
			if (data) obj = {...data};
			setState(obj);	
		}
	}, [visible]);

	return (
		<Modal
			title="Novo Agendamento"
			visible={visible}
			onOk={save}
			onCancel={onClose}
			footer={[
				<Button key="back" 
				onClick={onClose}
				disabled={loadingSave}
				>
					Cancelar
				</Button>,
				<Button
					type="primary"
					onClick={save}
					loading={loadingSave}
					disabled={loadingSave}
				>
					Enviar
				</Button>,
			]}
		>
			<Form layout="vertical">

				{
					state ? (
						<div>

						
							{!selectedDate ? (
									<Form.Item label="Data">
										<DatePicker
											format={"DD/MM/YYYY"}
											style={{ width: "100%" }}
											value={state.date || null}
											onChange={(e) => {
												setState((state) => {
													return {
														...state,
														date: e,
													};
												});
											}}
										/>
									</Form.Item>
							) : null}

							{
								(!loadingClients) ? (
									<div>
										{dataClients?.Clients.length ? (
											<Form.Item label="Cliente">
												<Select
													style={{ width: "100%" }}
													value={state?.client || null}
													onChange={(client) => {
														setState((state) => {
															return { ...state, client };
														});
													}}
												>
													{dataClients.Clients.map((item, index) => {
														return (
															<Option
																key={`cat_${index}`}
																value={item._id}
															>
																{item.company_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										) : (
											<div style={{marginBottom: 10}}>
												<Alert message="Você ainda não tem clientes cadastrados. Cadastre um antes de prosseguir." type="warning" />
											</div>
										)}
									</div>
								) : null
							}

							<Form.Item label="Descrição (Opcional)">
								<TextArea
									rows={3}
									value={state.description || null}
									onChange={(e) => {
										const { value } = e.target;
										setState((state) => {
											return { ...state, description: value };
										});
									}}
								/>
							</Form.Item>

							<Form.Item label="Status">
								<Select
									style={{ width: "100%" }}
									value={state?.status || null}
									onChange={(status) => {
										setState((state) => {
											return { ...state, status };
										});
									}}
								>
									<Option value={'cancelled'}>Cancelado</Option>
									<Option value={'pending'}>Pendente</Option>
									<Option value={'done'}>Realizado</Option>
								</Select>
							</Form.Item>
							


						</div>
					) : null
				}

			</Form>
		</Modal>
	);
}

export default ModalView;
