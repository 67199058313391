import {
	Layout,
	Table,
	Tag,
	Typography,
	Row,
	Col,
	Select,
	Form,
	DatePicker,
	Input,
	Button,
	Drawer,
	Upload,
} from "antd";

import { CheckCircleOutlined, InboxOutlined } from "@ant-design/icons";

import { useImmer } from "use-immer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;
const { Dragger } = Upload;

function Detail({ visible, close, title }) {
	const [state, setState] = useImmer({});

	return (
		<Drawer
			title={"Enviar arquivo"}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
		>
			<Form layout="vertical">
				<Row gutter={20}>
					<Col span={12}>
						<Form.Item label={"Tipo"}>
							<Select>
								<Select.Option>Extrato bancário</Select.Option>
								<Select.Option>Extrato de aplicação</Select.Option>
								<Select.Option>Nota fiscal de serviço tomado</Select.Option>
								<Select.Option>Nota fiscal de compra</Select.Option>
								<Select.Option>Boleto</Select.Option>
								<Select.Option>Comprovante de pagamento</Select.Option>
								<Select.Option>Planilhas/controles</Select.Option>
								<Select.Option>Demais documentos</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Competência"}>
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Descrição"}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Col>

					<Col span={24}>
						<Dragger>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
							<p className="ant-upload-hint">
								Support for a single or bulk upload. Strictly prohibit from
								uploading company data or other band files
							</p>
						</Dragger>
					</Col>
					<Col span={24}>
						<div className="align-right" style={{ paddingTop: 20 }}>
							<Button type="primary">Salvar</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
}

function Agenda() {
	const [task, setTask] = useImmer({
		visible: false,
		title: "",
	});

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					<div className="dashboard-view">
						<div className="page-title">
							<Title>Documentos Enviados</Title>
							<Button
								onClick={() => {
									setTask({
										visible: true,
									});
								}}
								type="primary"
							>
								Enviar
							</Button>
							<Detail
								{...task}
								close={() => {
									setTask({
										visible: false,
									});
								}}
							/>
						</div>

						<div>
							<Row gutter={20}>
								<Col span={6}>
									<Form.Item label="Tipo">
										<Select value={""} onChange={(e) => {}}>
											<Option value="all">Todos</Option>
											<Option value="1">Cadastral</Option>
											<Option value="2">Declaração</Option>
											<Option value="3">Pagamento</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item label="Status">
										<Select value={""} onChange={(e) => {}}>
											<Option value="all">Todos</Option>
											<Option value="1">Em aberto</Option>
											<Option value="1">Agendados</Option>
											<Option value="1">Pagos</Option>
											<Option value="2">Vencido</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Período"}>
										<DatePicker.RangePicker
											style={{
												width: "100%",
											}}
											format={"DD/MM/YYYY"}
										/>
									</Form.Item>
								</Col>
							</Row>
						</div>

						<Table
							columns={[
								{
									key: "type",
									dataIndex: "type",
									title: "Tipo",
								},
								{
									key: "document",
									dataIndex: "document",
									title: "Documento",
								},
								{
									key: "competence",
									dataIndex: "competence",
									title: "Competência",
								},
								{
									key: "read",
									dataIndex: "read",
									title: "Lido",
									render: (value) => (value ? <CheckCircleOutlined /> : "-"),
								},
								{
									key: "approvalStatus",
									dataIndex: "approvalStatus",
									title: "Status de Aprovação",
									render: (e) => {
										if (e === "pending") {
											return <Tag color="orange">Pendente</Tag>;
										}
									},
								},
								{
									key: "status",
									dataIndex: "status",
									title: "Status",
									render: (e) => {
										if (e === "active") {
											return <Tag color="green">Ativo</Tag>;
										}
									},
								},
							]}
							dataSource={[
								{
									type: "Guia",
									document: "ISS dia 20",
									competence: "Maio 2024",
									dueDate: "20/05/2024",
									read: true,
									approvalStatus: "pending",
									status: "active",
								},
							]}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	);
}

export default Agenda;
