import React, { useState, useEffect, useRef } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Swal from "sweetalert2";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from "lodash";
import MaskedInput from "antd-mask-input";
import isEmail from "validator/lib/isEmail";

import moment from "moment";
import { useImmer } from "use-immer";

import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	notification,
	DatePicker,
	Checkbox,
	Spin,
	Alert,
	Upload
} from "antd";

import {
	UploadOutlined,
	FileDoneOutlined,
	FilePdfFilled,
} from "@ant-design/icons";

import { statuses } from "../Misc";
import Address from "./Address";
import BusinessStatus from "../util/BusinessStatus";
import { gql } from "@apollo/client";

const { Content } = Layout;
const { Title } = Typography;

function Ancord({ id, business_id, disabled, getValue, setValue }) {
	const check = useQuery(
		gql`
			query BusinessAncordCheck($uid: String!, $business_id: String!) {
				BusinessAncordCheck(uid: $uid, business_id: $business_id)
			}
		`,
		{
			fetchPolicy: "no-cache",
			variables: {
				uid: id,
				business_id,
			},
		}
	);

	if (check?.data?.BusinessAncordCheck) {
		return (
			<div>
				<hr style={{ marginTop: 20, marginBottom: 20 }} />
				<h3>ANCORD</h3>
				<Row gutter={20}>
					<Col span={12}>
						<Form.Item label="Habilitado">
							<Checkbox
								disabled={disabled}
								defaultChecked={getValue("ancord_enabled")}
								onChange={(e) => {
									const { checked } = e.target;
									setValue("ancord_enabled", checked);
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Contrato Social/NIRE">
							<Input
								disabled={disabled}
								defaultValue={getValue("nire")}
								onChange={(e) => {
									const { value } = e.target;
									setValue("nire", value);
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Data de Fundação">
							<DatePicker
								format={"DD/MM/YYYY"}
								style={{ width: "100%" }}
								disabled={disabled}
								defaultValue={
									getValue("activity_start")
										? moment(getValue("activity_start"))
										: null
								}
								onChange={(e) => {
									setValue("activity_start", e.toISOString());
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Data de Arquivamento ">
							<DatePicker
								format={"DD/MM/YYYY"}
								style={{ width: "100%" }}
								disabled={disabled}
								defaultValue={
									getValue("register_date")
										? moment(getValue("register_date"))
										: null
								}
								onChange={(e) => {
									setValue("register_date", e.toISOString());
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<hr style={{ marginTop: 20, marginBottom: 20 }} />
			</div>
		);
	}

	return null;
}

function CNAEList({ id, business_id }) {
	const cnaes = useQuery(
		gql`
	query BusinessCNAES($uid: String!, $business_id: String!) {
    BusinessCNAES(uid: $uid, business_id: $business_id) {
      code
      description
    }
  }
	`,
		{
			fetchPolicy: "no-cache",
			variables: {
				uid: id,
				business_id,
			},
		}
	);

	return (
		<Col span={12} style={{ marginTop: 20 }}>
			<Title level={5}>CNAEs</Title>

			{!cnaes.loading ? (
				<Table
					dataSource={cnaes.data.BusinessCNAES}
					pagination={false}
					scroll={{ y: 400 }}
					columns={[
						{
							title: "Código",
							dataIndex: "code",
							key: "code",
							width: 150,
						},
						{
							title: "Descrição",
							dataIndex: "description",
							key: "description",
						},
					]}
				/>
			) : null}
		</Col>
	);
}

function CTNList({ id, value, disabled, onChange }) {
	const mounted = useRef(false);
	const [search, setSearch] = useImmer(null);
	const [values, setValues] = useImmer(value || []);

	const codes = useQuery(
		gql`
	{
		InvoicesCodes {
			id
			text
		}
	}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (mounted.current) {
			onChange(values);
		}
	}, [values]);

	const getValues = (data) => {
		if (search) {
			let filter = data.filter((i) => {
				let value = i.text.toLowerCase();
				if (value.indexOf(search.toLowerCase()) > -1) {
					return i;
				}
			});

			return filter;
		} else {
			return data;
		}
	};

	return (
		<Col span={12} style={{ marginTop: 20 }}>
			<Title level={5}>Códigos de Tributação Nacional</Title>

			{codes.loading ? (
				<div
					style={{
						padding: 20,
						textAlign: "center",
					}}
				>
					<Spin />
				</div>
			) : null}

			{codes.error ? <Error refetch={codes.refetch} /> : null}

			{codes?.data?.InvoicesCodes?.length ? (
				<div className="ctn-list-wrapper">
					<div className="ctn-list-search">
						<Input
							disabled={disabled}
							placeholder="Buscar"
							onChange={(e) => {
								const { value } = e.target;
								setSearch(value);
							}}
						/>
					</div>
					<div className="ctn-list">
						{getValues(codes.data.InvoicesCodes)?.length ? (
							getValues(codes.data.InvoicesCodes).map((item, i) => {
								const checked = values.findIndex((i) => i === item.id);

								return (
									<div className="check-item">
										<Checkbox
											value={item.id}
											disabled={disabled}
											checked={checked > -1 ? true : false}
											onChange={(e) => {
												if (e.target.checked) {
													setValues((d) => {
														d.push(e.target.value);
													});
												} else {
													setValues((d) => {
														const index = d.findIndex(
															(i) => i === e.target.value
														);
														if (index !== -1) d.splice(index, 1);
													});
												}
											}}
										>
											{item.text}
										</Checkbox>
									</div>
								);
							})
						) : (
							<div>Nenhum resultado</div>
						)}
					</div>
				</div>
			) : null}
		</Col>
	);
}

function User({ id, business_id, updateTop }) {
	const [disabled, setDisabled] = useState(true);
	const [state, setState] = useState(null);

	const ancordRef = useRef(null);

	const [saveAction, { loading: loadingSave }] = useMutation(
		Queries.BUSINESS_SAVE
	);

	const { loading, data, error, refetch } = useQuery(Queries.BUSINESS, {
		fetchPolicy: "no-cache",
		variables: {
			id,
			business_id,
		},
	});

	useEffect(() => {
		if (data) setState(data.Business || {});
	}, [data]);

	const getValue = (path) => {
		return _.get(state, path);
	};

	const setValue = (path, value) => {
		setState((state) => {
			_.set(state, path, value);
			return state;
		});
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		if (!state.company_name)
			return ErrorDialog("Por favor, insira uma Razão Social válida");

		let obj = {
			...state,
		};

		console.log(obj);

		const { data, error } = await saveAction({
			variables: {
				input: {
					...obj,
				},
				uid: id,
				business_id,
			},
		});

		if (error) {
			ErrorDialog(
				"Não foi possível salvar as informações no momento. Tente novamente mais tarde."
			);
		} else {
			notification.success({
				message: "Sucesso",
				description: "Informações salvas com sucesso",
			});
			refetch();
			updateTop(obj);
		}
	};

	const empty = (obj) => {
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				return false;
			}
		}
		return JSON.stringify(obj) === JSON.stringify({});
	};

	console.log(data);

	return (
		<Card
			title="Empresa"
			extra={
				<Button onClick={() => setDisabled((state) => !state)}>
					{disabled ? "Editar" : "Cancelar"}
				</Button>
			}
		>
			{loading ? <Loading /> : null}

			{!loading && error ? <Error refetch={refetch} /> : null}

			{state && !loading && !error ? (
				<div>
					{empty(state) ? (
						<div style={{ marginBottom: "20px" }}>
							<Alert
								message="Empresa não encontrada. Favor preencher os dados."
								type="error"
							/>
						</div>
					) : null}

					<Form layout="vertical">
						<Row gutter={20}>
							<Col span={16}>
								<Form.Item name="razao" label="Razão Social">
									<Input
										disabled={disabled}
										defaultValue={getValue("company_name")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("company_name", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="cnpj" label="CNPJ">
									<MaskedInput
										mask={"11.111.111/1111-11"}
										disabled={disabled}
										defaultValue={getValue("cnpj")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("cnpj", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={16}>
								<Form.Item name="trade_name" label="Nome Fantasia">
									<Input
										disabled={disabled}
										defaultValue={getValue("trade_name")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("trade_name", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="status" label="Status">
									<Select
										disabled={disabled}
										defaultValue={getValue("status")}
										onChange={(e) => {
											console.log(e);
											setValue("status", e);
										}}
										options={BusinessStatus()}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="email" label="E-mail da Empresa">
									<Input
										disabled={disabled}
										defaultValue={getValue("email")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("email", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									name="municipal_registration"
									label="Inscrição Municipal"
								>
									<Input
										disabled={disabled}
										defaultValue={getValue("municipal_registration")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("municipal_registration", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={24}>
								<hr />
								<h3 style={{ marginBottom: 20, marginTop: 20 }}>
									Dados de Acesso a Orgãos
								</h3>
							</Col>

							<Col span={8}>
								<Form.Item label="Senha Emissor Nacional">
									<Input.Password
										defaultValue={state?.external_password || ""}
										disabled={disabled}
										onChange={(e) => {
											const { value } = e.target;
											setValue("external_password", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={8}>
								<Form.Item label="Login Prefeitura">
									<Input.Password
										defaultValue={state?.city_login || ""}
										disabled={disabled}
										onChange={(e) => {
											const { value } = e.target;
											setValue("city_login", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={8}>
								<Form.Item label="Senha Prefeitura">
									<Input.Password
										defaultValue={state?.city_password || ""}
										disabled={disabled}
										onChange={(e) => {
											const { value } = e.target;
											setValue("city_password", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={4}>
								<Form.Item label="Usa Certificado">
									<Select
										disabled={disabled}
										defaultValue={state?.certificate_used || ""}
										onChange={(e) => {
											console.log(e);
											setValue("certificate_used", e);
										}}
									>
										<Select.Option value={"yes"}>Sim</Select.Option>
										<Select.Option value={"no"}>Não</Select.Option>
									</Select>
								</Form.Item>
							</Col>

							<Col span={4}>
								<Form.Item label="Vencimento Certificado">
									<MaskedInput
										mask="11/11/1111"
										disabled={disabled}
										defaultValue={getValue("certificate_duedate")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("certificate_duedate", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={8}>
								<Form.Item label={"Arquivo Certificado"}>
									<Upload
										beforeUpload={() => false}
										
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
							</Col>

							<Col span={8}>
								<Form.Item label="Senha Certificado">
									<Input.Password disabled={disabled} type="password" />
								</Form.Item>
							</Col>

							<Col span={24}>
								<Ancord
									id={id}
									business_id={business_id}
									disabled={disabled}
									setValue={(arg1, arg2) => setValue(arg1, arg2)}
									getValue={(val) => getValue(val)}
								/>
							</Col>

							<CNAEList id={id} business_id={business_id} />

							<CTNList
								id={id}
								value={state?.ctn}
								disabled={disabled}
								onChange={(values) => {
									setValue("ctn", values);
								}}
							/>

							{!disabled ? (
								<Col span={24} style={{ marginTop: 20 }}>
									<Button
										type="primary"
										style={{ float: "right" }}
										onClick={save}
										loading={loadingSave}
									>
										Salvar
									</Button>
								</Col>
							) : null}
						</Row>
					</Form>
				</div>
			) : null}
		</Card>
	);
}

export default User;
