import React, { useState } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

import { Col, Row, Layout, Table, Typography, Popover } from "antd";

const { Content } = Layout;
const { Title } = Typography;

function Services() {
	const { loading, data, error, refetch } = useQuery(Queries.REFERS, {
		fetchPolicy: "no-cache",
	});

	const columns = [
		{
			title: "Nome",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "E-mail",
			dataIndex: "email",
			key: "email",
			render: (value) => value ?? '-'
		},
		{
			title: "Telefone",
			dataIndex: "phone",
			key: "phone",
			render: (value) => value ?? '-'
		},
		{
			title: "Indicado por",
			dataIndex: "referred_by",
			key: "referred_by",
			render: (value) => value ?? '-'
		},
		{
			title: "Mensagem",
			dataIndex: "message",
			key: "message",
			width: 300,
			render: (value) => {
				if(value?.length > 50) {
					return (
						<Popover overlayClassName="message-popover" placement="topRight" content={value} title="Mensagem" style={{width: 200}}>
								{`${value.slice(0, 50)}...`}
						</Popover>
					)
				} return value;
			}
		},
	];

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Clube UAU</Title>
				</div>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data?.Refers?.length ? data.Refers : []}
								columns={columns}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>
		</Layout>
	);
}

export default Services;
