import {
	Layout,
	Typography,
	Tree,
	Col,
	Row,
	Table,
	Tag,
	Form,
	Select,
	Input,
	Tabs,
	Drawer,
	Button,
	DatePicker,
	Upload,
	Modal,
	Descriptions,
	Card,
	notification,
	List,
	Divider,
} from "antd";

import { FileOutlined, InboxOutlined } from "@ant-design/icons";
import "moment/locale/pt-br";
import { useImmer } from "use-immer";
import User from "store/user";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import moment from "moment";
import UserSearch from "components/UserSearch";
import _ from "lodash";
import "moment/locale/pt-br";
import { useEffect } from "react";

moment.locale("pt-br");

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
const { DirectoryTree } = Tree;

function renderStatus(status) {
	if (status === "pending") return <Tag color="orange">Pendente</Tag>;
}

function Add({ visible, close, refetch }) {
	const [state, setState] = useImmer({});
	const [files, setFiles] = useImmer([]);

	const [saveAction, { loading }] = useMutation(
		gql`
			mutation PartnerDocument($input: PartnerDocumentInput!) {
				PartnerDocument(input: $input)
			}
	`,
		{
			onCompleted: (data) => {
				if (data.PartnerDocument) {
					refetch();
					close();
				} else {
					notification.error({
						description: "Não foi possível enviar o documento.",
					});
				}
			},
			onError: () => {
				notification.error({
					description: "Não foi possível enviar o documento.",
				});
			},
		}
	);

	const save = async () => {
		if (!state.type)
			return Modal.error({
				content: "Por favor, selecione um tipo.",
			});
		if (!state.type)
			return Modal.error({
				content: "Por favor, selecione uma competência.",
			});
		if (!state.sent_to)
			return Modal.error({
				content: "Por favor, selecione um cliente.",
			});
		if (!state.status)
			return Modal.error({
				content: "Por favor, selecione um status.",
			});
		if (!state.statusApproval)
			return Modal.error({
				content: "Por favor, selecione um status de aprovação.",
			});

		let payload = {
			...state,
			files: files,
		};

		await saveAction({
			variables: {
				input: payload,
			},
		});
	};

	return (
		<Drawer
			title={"Enviar documento"}
			placement="right"
			onClose={() => {
				if (!loading) close();
			}}
			width={800}
			visible={visible}
			afterOpenChange={(open) => {
				if (!open) {
					setState({});
					setFiles([]);
				}
			}}
		>
			<Form layout="vertical">
				<Row gutter={20}>
					<Col span={12}>
						<Form.Item label={"Tipo"}>
							<Select
								onChange={(e) => {
									setState((d) => {
										d.type = e;
									});
								}}
								value={state?.type}
							>
								<Select.Option value="Cadastral">Cadastral</Select.Option>
								<Select.Option value="Declaração">Declaração</Select.Option>
								<Select.Option value="Pagamento">Pagamento</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Competência"}>
							<DatePicker
								style={{ width: "100%" }}
								onChange={(e) => {
									setState((d) => {
										d.competence = e;
									});
								}}
								format={"DD/MM/YYYY"}
								value={state?.competence}
							/>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Cliente"}>
							<UserSearch
								onChange={(e) => {
									setState((d) => {
										d.sent_to = e;
									});
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Status"}>
							<Select
								onChange={(e) => {
									setState((d) => {
										d.status = e;
									});
								}}
								value={state?.status}
							>
								<Select.Option value="pending">Pendente</Select.Option>
								<Select.Option value="active">Ativo</Select.Option>
								<Select.Option value="inactive">Inativo</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Status Aprovação"}>
							<Select
								onChange={(e) => {
									setState((d) => {
										d.statusApproval = e;
									});
								}}
								value={state?.statusApproval}
							>
								<Select.Option value="pending">Pendente</Select.Option>
								<Select.Option value="rejected">Rejeitado</Select.Option>
								<Select.Option value="approved">Aprovado</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Descrição"}>
							<Input.TextArea
								rows={5}
								onChange={(e) => {
									setState((d) => {
										d.description = e.target.value;
									});
								}}
								value={state?.description}
							/>
						</Form.Item>
					</Col>

					<Col span={24}>
						<div>
							<Dragger
								onChange={(e) => {
									setFiles(e.fileList);
								}}
								multiple
								beforeUpload={() => false}
								fileList={files}
							>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Clique para selecionar ou arraste arquivos aqui
								</p>
							</Dragger>
						</div>
					</Col>
					<Col span={24}>
						<div className="align-right" style={{ paddingTop: 20 }}>
							<Button type="primary" onClick={save} loading={loading}>
								Salvar
							</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
}

function Detail({ visible, close, data, source }) {
	const [files, setFiles] = useImmer(null);
	const [uploadFiles, setUploadFiles] = useImmer([]);
	const [state, setState] = useImmer({});

	const [getDocument, { loading, refetch }] = useLazyQuery(
		gql`
		query PartnerDocument($id: String!, $source: String!) {
			PartnerDocument(id: $id, source: $source) {
				files {
					mime
					key
					name
				}
				type
				competence
				status
				statusApproval
				description
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			onCompleted: (res) => {
				let { files, ...reqData } = res.PartnerDocument;
				if (reqData.competence) reqData.competence = moment(reqData.competence);
				if (data.user_id) reqData.sent_to = data.user_id;
				setState(reqData);
				setFiles(files);
			},
		}
	);

	const [saveAction, { loading: loadingSave }] = useMutation(
		gql`
			mutation PartnerDocument($input: PartnerDocumentInput!, $id: String) {
				PartnerDocument(input: $input, id: $id)
			}
	`,
		{
			onCompleted: (res) => {
				if (res.PartnerDocument) {
					refetch({
						id: data._id,
						source,
					});
					setUploadFiles([]);
					notification.success({
						description: "Documento atualizado.",
					});
				} else {
					notification.error({
						description: "Não foi possível enviar o documento.",
					});
				}
			},
			onError: () => {
				notification.error({
					description: "Não foi possível enviar o documento.",
				});
			},
		}
	);

	const save = async () => {
		console.log(state);

		if (!state.type)
			return Modal.error({
				content: "Por favor, selecione um tipo.",
			});
		if (!state.type)
			return Modal.error({
				content: "Por favor, selecione uma competência.",
			});
		if (!state.sent_to)
			return Modal.error({
				content: "Por favor, selecione um MEI.",
			});
		if (!state.status)
			return Modal.error({
				content: "Por favor, selecione um status.",
			});
		if (!state.statusApproval)
			return Modal.error({
				content: "Por favor, selecione um status de aprovação.",
			});

		let payload = {
			...state,
			files: uploadFiles,
		};

		await saveAction({
			variables: {
				input: payload,
				id: data._id,
			},
		});
	};

	useEffect(() => {
		if (visible && data?._id) {
			getDocument({
				variables: {
					id: data._id,
					source,
				},
			});
		}
	}, [visible]);

	return (
		<Drawer
			title={"Documento"}
			placement="right"
			onClose={() => {
				if (!loadingSave) close();
			}}
			width={800}
			visible={visible}
			afterOpenChange={(open) => {
				if (!open) {
					setFiles(null);
				}
			}}
		>
			{state ? (
				<>
					<Descriptions bordered>
						<Descriptions.Item label="Tipo" span={3}>
							<Select
								style={{
									width: "100%",
								}}
								onChange={(e) => {
									setState((d) => {
										d.type = e;
									});
								}}
								value={state?.type}
							>
								<Select.Option value="Extrato bancário">
									Extrato bancário
								</Select.Option>
								<Select.Option value="Extrato de aplicação">
									Extrato de aplicação
								</Select.Option>
								<Select.Option value="Nota fiscal de serviço tomado">
									Nota fiscal de serviço tomado
								</Select.Option>
								<Select.Option value="Nota fiscal de compra">
									Nota fiscal de compra
								</Select.Option>
								<Select.Option value="Boleto">Boleto</Select.Option>
								<Select.Option value="Comprovante de pagamento">
									Comprovante de pagamento
								</Select.Option>
								<Select.Option value="Planilhas/controles">
									Planilhas/controles
								</Select.Option>
								<Select.Option value="Demais documentos">
									Demais documentos
								</Select.Option>
							</Select>
						</Descriptions.Item>
						<Descriptions.Item label="Cliente" span={3}>
							{data ? (
								<UserSearch
									additionalProps={{
										style: {
											width: "100%",
										},
									}}
									value={data.user_name}
									onChange={(e) => {
										setState((d) => {
											d.sent_to = e;
										});
									}}
								/>
							) : null}
						</Descriptions.Item>

						<Descriptions.Item label="Competência" span={3}>
							<DatePicker
								style={{ width: "100%" }}
								onChange={(e) => {
									setState((d) => {
										d.competence = e;
									});
								}}
								format={"DD/MM/YYYY"}
								value={state?.competence}
							/>
						</Descriptions.Item>

						<Descriptions.Item label="Status" span={3}>
							<Select
								style={{
									width: "100%",
								}}
								onChange={(e) => {
									setState((d) => {
										d.status = e;
									});
								}}
								value={state?.status}
							>
								<Select.Option value="pending">Pendente</Select.Option>
								<Select.Option value="active">Ativo</Select.Option>
								<Select.Option value="inactive">Inativo</Select.Option>
							</Select>
						</Descriptions.Item>
						<Descriptions.Item label="Status Aprovação" span={3}>
							<Select
								style={{
									width: "100%",
								}}
								onChange={(e) => {
									setState((d) => {
										d.statusApproval = e;
									});
								}}
								value={state?.statusApproval}
							>
								<Select.Option value="pending">Pendente</Select.Option>
								<Select.Option value="rejected">Rejeitado</Select.Option>
								<Select.Option value="approved">Aprovado</Select.Option>
							</Select>
						</Descriptions.Item>
						<Descriptions.Item label="Descrição" span={3}>
							<Input.TextArea
								rows={5}
								onChange={(e) => {
									setState((d) => {
										d.description = e.target.value;
									});
								}}
								value={state?.description}
							/>
						</Descriptions.Item>
					</Descriptions>

					{loading && <Loading />}

					{files?.length ? (
						<div>
							<Divider />
							<h3>Arquivos</h3>
							<List
								dataSource={files}
								renderItem={(item) => (
									<List.Item
										actions={[
											<Button
												onClick={() => {
													window.open(item.key);
												}}
											>
												Ver
											</Button>,
										]}
									>
										<List.Item.Meta
											avatar={<FileOutlined />}
											title={item.name}
											description={item.mime}
										/>
									</List.Item>
								)}
							/>
						</div>
					) : null}

					<div>
						<Dragger
							onChange={(e) => {
								setUploadFiles(e.fileList);
							}}
							multiple
							beforeUpload={() => false}
							fileList={uploadFiles}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Clique para selecionar ou arraste arquivos aqui
							</p>
						</Dragger>
					</div>

					<div className="align-right" style={{ paddingTop: 20 }}>
						<Button type="primary" onClick={save} loading={loadingSave}>
							Salvar
						</Button>
					</div>
				</>
			) : null}
		</Drawer>
	);
}

function Dashboard() {
	const [add, setAdd] = useImmer({
		visible: false,
	});
	const [detail, setDetail] = useImmer({
		visible: false,
	});
	const [source, setSource] = useImmer("sent");
	const [dataSource, setDataSource] = useImmer([]);
	const [tree, setTree] = useImmer(null);

	const [localFilter, setLocalFilter] = useImmer({});

	const [load, { loading, error, refetch }] = useLazyQuery(
		gql`
		query PartnerDocuments($source: String!) {
			PartnerDocuments(source: $source) {
				_id
				user_id
				type
				competence
				status
				statusApproval
				description
				user_name
			}
		}
	`,
		{
			variables: {
				source: "sent",
			},
			fetchPolicy: "no-cache",
			onCompleted: (data) => {
				setDataSource(data.PartnerDocuments);

				let tree = [];

				for (const doc of data.PartnerDocuments) {
					let competence = moment(doc.competence);

					let title = doc.type;
					let title_id = _.kebabCase(doc.type);
					let subTitle = competence.format("MM/YYYY");
					let sub_id = `${title_id}_${competence.format("MM_YYYY")}`;

					// Check if main doc exists

					let index = tree.findIndex((i) => i.key === title_id);
					if (index < 0) {
						tree.push({
							title,
							key: title_id,
							value: {
								type: title,
							},
							children: [
								{
									title: subTitle,
									key: sub_id,
									value: {
										competence: competence.format("MM/YYYY"),
										type: title,
									},
								},
							],
						});
					} else {
						let subIndex = tree[index].children.findIndex(
							(i) => i.key === sub_id
						);
						if (subIndex < 0) {
							tree[index].children.push({
								title: subTitle,
								key: sub_id,
								value: {
									competence: competence.format("MM/YYYY"),
									type: title,
								},
							});
						}
					}
				}

				let finalTree = [
					{
						title: "Todos",
						key: "all",
						children: tree,
					},
				];

				setTree(finalTree);
			},
		}
	);

	useEffect(() => {
		load();
	}, [])

	const getData = () => {
		if (!dataSource.length) return [];

		console.log(dataSource);

		if (localFilter) {
			if (Object.keys(localFilter).length) {
				let filtered = dataSource.filter((item) => {
					if (localFilter.type && item.type !== localFilter.type) {
						return false;
					}

					if (localFilter.user_id && item.user_id !== localFilter.user_id) {
						return false;
					}

					if (localFilter.period) {
						let itemDate = moment(item.competence);

						if (
							!itemDate.isBetween(localFilter.period[0], localFilter.period[1])
						) {
							return false;
						}
					}

					if (localFilter.status && item.status !== localFilter.status) {
						return false;
					}

					if (localFilter.competence) {
						let itemDate = moment(item.competence).format("MM/YYYY");
						if (itemDate !== localFilter.competence) {
							return false;
						}
					}

					return item;
				});

				return filtered;
			}
		}

		return dataSource;
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					<div className="dashboard-view">
						<div className="page-title">
							<Title>Documentos</Title>

							<Button
								onClick={() => {
									setAdd({
										visible: true,
									});
								}}
								type="primary"
							>
								Enviar
							</Button>
							<Add
								{...add}
								source={source}
								refetch={() => {
									load({
										variables: {
											source
										}
									});
								}}
								close={() => {
									setAdd({
										visible: false,
									});
								}}
							/>
							<Detail
								{...detail}
								source={source}
								close={() => {
									setDetail({
										visible: false,
									});
								}}
							/>
						</div>
						<Tabs
							onChange={(e) => {
								setSource(e);
								// refetch({
								// 	source: e,
								// });
								load({
									variables: {
										source: e
									}
								})
							}}
						>
							<Tabs.TabPane tab="Enviados" key="sent"></Tabs.TabPane>
							<Tabs.TabPane tab="Recebidos" key="received"></Tabs.TabPane>
						</Tabs>

						<div>
							<Form layout="vertical">
								<Row gutter={20}>
									<Col span={6}>
										<Form.Item label="Cliente">
											<UserSearch
												onChange={(e) => {
													setLocalFilter((d) => {
														d.user_id = e;
													});
												}}
												additionalProps={{
													allowClear: true
												}}
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item label="Status">
											<Select
												value={localFilter.status || "all"}
												onChange={(e) => {
													if (e !== "all") {
														setLocalFilter((d) => {
															d.status = e;
														});
													} else {
														setLocalFilter((d) => {
															d.status = null;
														});
													}
												}}
											>
												<Option value="all">Todos</Option>
												<Option value="pending">Pendente</Option>
												<Option value="scheduled">Agendados</Option>
												<Option value="paid">Pagos</Option>
												<Option value="overdue">Vencido</Option>
											</Select>
										</Form.Item>
									</Col>
									<Col flex={1}>
										<Form.Item label={"Período"}>
											<DatePicker.RangePicker
												style={{
													width: "100%",
												}}
												format={"DD/MM/YYYY"}
												onChange={(e) => {
													setLocalFilter((d) => {
														d.period = e;
													});
												}}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>

						{loading ? <Loading /> : null}

						{!loading && error ? <Error refetch={() => refetch()} /> : null}

						{!loading && !error && (
							<div
								style={{
									border: "1px solid #e5e5e5",
								}}
							>
								<Row>
									<Col
										span={4}
										sm={5}
										style={{
											borderRight: "1px solid #e5e5e5",
										}}
									>
										<div
											style={{
												padding: 20,
											}}
										>
											{tree ? (
												<DirectoryTree
													defaultExpandAll
													onSelect={(selectedKey, e) => {
														setLocalFilter({
															...localFilter,
															...e.node.value,
														});
													}}
													treeData={tree}
													style={{
														padding: 0,
														paddingTop: 0,
													}}
												/>
											) : null}
										</div>
									</Col>
									<Col
										flex={1}
										style={{
											padding: 30,
										}}
									>
										<Table
											columns={[
												{
													key: "type",
													dataIndex: "type",
													title: "Tipo",
												},
												{
													key: "description",
													dataIndex: "description",
													title: "Descrição",
												},
												{
													key: "competence",
													dataIndex: "competence",
													title: "Competência",
													render: (value) =>
														value ? moment(value).format("DD/MM/YYYY") : "-",
												},
												// {
												// 	key: "read",
												// 	dataIndex: "read",
												// 	title: "Lido",
												// 	render: (value) =>
												// 		value ? <CheckCircleOutlined /> : "-",
												// },
												{
													key: "statusApproval",
													dataIndex: "statusApproval",
													title: "Status de Aprovação",
													render: (e) => renderStatus(e),
												},
												{
													key: "status",
													dataIndex: "status",
													title: "Status",
													render: (e) => renderStatus(e),
												},
											]}
											dataSource={getData()}
											onRow={(record, rowIndex) => {
												return {
													onClick: (event) => {
														setDetail({
															visible: true,
															data: record,
														});
													}, // click row
												};
											}}
										/>
									</Col>
								</Row>
							</div>
						)}
					</div>
				</div>
			</Content>
		</Layout>
	);
}

export default Dashboard;
