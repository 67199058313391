import React, { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from "lodash";
import isEmail from "validator/lib/isEmail";
import {
	Col,
	Row,
	Layout,
	Divider,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	notification,
	DatePicker,
} from "antd";
import MaskedInput from "antd-mask-input";
import PartnerSearch from "components/PartnerSearch";

import InputNumber from "components/InputNumber";

import { statuses } from "../Misc";
const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

function User({ id }) {
	const [disabled, setDisabled] = useState(true);
	const [state, setState] = useState(null);

	const { loading, data, error, refetch } = useQuery(Queries.USER, {
		fetchPolicy: "no-cache",
		variables: {
			id,
		},
	});
	const [saveAction, { loading: loadingSave }] = useMutation(Queries.USER_SAVE);

	useEffect(() => {
		if (data) setState(data.User);
	}, [data]);

	const getValue = (path) => {
		return _.get(state, path);
	};

	const setValue = (path, value) => {
		setState((state) => {
			_.set(state, path, value);
			return state;
		});
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		if (!state.name) return ErrorDialog("Por favor, insira um nome válido");

		const { partner_name, ...rest } = state;

		let obj = {
			_id: id,
			...rest,
		};

		if (obj.status) obj.status = parseInt(obj.status);

		const { data, error } = await saveAction({
			variables: {
				input: obj,
			},
		});

		if (error) {
			ErrorDialog(
				"Não foi possível salvar as informações no momento. Tente novamente mais tarde."
			);
		} else {
			notification.success({
				message: "Sucesso",
				description: "Informações salvas com sucesso",
			});
			refetch();
		}
	};

	return (
		<Card
			title="Informações Básicas"
			extra={
				<Button onClick={() => setDisabled((state) => !state)}>
					{disabled ? "Editar" : "Cancelar"}
				</Button>
			}
		>
			{loading ? <Loading /> : null}

			{!loading && error ? <Error refetch={refetch} /> : null}

			{state && !loading && !error ? (
				<Form layout="vertical">
					<Row gutter={20}>
						<Col span={16}>
							<Form.Item name="name" label="Nome">
								<Input
									disabled={disabled}
									defaultValue={getValue("name")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("name", value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="phone" label="Fone">
								<MaskedInput
									mask="(11) 11111-1111"
									disabled={disabled}
									defaultValue={getValue("phone")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("phone", value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="email" label="E-mail">
								<Input
									disabled={disabled}
									defaultValue={getValue("email")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("email", value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="password" label="Senha Usuário">
								<Input.Password
									disabled={disabled}
									onChange={(e) => {
										const { value } = e.target;
										setValue("password", value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="cpf" label="CPF">
								<MaskedInput
									mask="111.111.111-11"
									disabled={disabled}
									defaultValue={getValue("cpf")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("cpf", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item label="Status">
								<Select
									disabled={disabled}
									defaultValue={state.status.toString()}
									onChange={(e) => {
										setValue("status", e);
									}}
								>
									<Select.Option value="0">Expirado</Select.Option>
									<Select.Option value="1">Ativo</Select.Option>
									<Select.Option value="2">
										Não cadastrou/requisitou empresa
									</Select.Option>
									<Select.Option value="3">
										Aguardando Abertura / Doc. Pendente
									</Select.Option>
									<Select.Option value="4">Inativo</Select.Option>
									<Select.Option value="5">Cancelado</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Status do Contrato">
								<Select
									disabled={disabled}
									defaultValue={state?.contract_status}
									onChange={(e) => {
										setValue("contract_status", e);
									}}
								>
									<Select.Option value="signed">Assinado</Select.Option>
									<Select.Option value="sent">Enviado</Select.Option>
									<Select.Option value="sending">Enviando</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Plano">
								<Select
									disabled={disabled}
									defaultValue={state.plan}
									onChange={(e) => {
										setValue("plan", e);
									}}
								>
									<Select.Option value="super">Super</Select.Option>
									<Select.Option value="premium">Premium</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item label="Valor">
								<InputNumber disabled={disabled} />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item label="Dia vencimento">
								<Select
									disabled={disabled}
									options={Array.from({ length: 31 }).map((i, idx) => {
										return {
											label: idx + 1,
											value: idx + 1,
										};
									})}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item label="Forma de Pagamento">
								<Select
									disabled={disabled}
									options={["Boleto", "PIX", "Cartão"].map((i) => {
										return {
											label: i,
											value: i,
										};
									})}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item label="Meio de Pagamento">
								<Select
									disabled={disabled}
									options={["Cora", "Faturágil", "Pagseguro"].map((i) => {
										return {
											label: i,
											value: i,
										};
									})}
								/>
							</Form.Item>
						</Col>

						<Col span={24}>
							<hr />
							<h3 style={{ marginBottom: 20, marginTop: 20 }}>
								Dados de Acesso a Orgãos
							</h3>
						</Col>

						<Col span={8}>
							<Form.Item label="Senha gov.br">
								<Input.Password
									defaultValue={state?.gov_br_password || ""}
									disabled={disabled}
									onChange={(e) => {
										const { value } = e.target;
										setValue("gov_br_password", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item label="Usa Certificado">
								<Select
									disabled={disabled}
									defaultValue={state?.certificate_used || ""}
									onChange={(e) => {
										console.log(e);
										setValue("certificate_used", e);
									}}
								>
									<Select.Option value={"yes"}>Sim</Select.Option>
									<Select.Option value={"no"}>Não</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item label="Vencimento Certificado">
								<MaskedInput
									mask="11/11/1111"
									disabled={disabled}
									defaultValue={getValue("certificate_duedate")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("certificate_duedate", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={24}>
							<hr />
						</Col>

						<Col span={24}>
							<Form.Item label="Observações Gerais">
								<TextArea
									disabled={disabled}
									rows={4}
									defaultValue={state?.notes}
									onChange={(e) => {
										const { value } = e.target;
										setValue("notes", value);
									}}
								/>
							</Form.Item>
						</Col>

						{!disabled ? (
							<Col span={24}>
								<Button
									type="primary"
									style={{ float: "right" }}
									onClick={save}
									loading={loadingSave}
								>
									Salvar
								</Button>
							</Col>
						) : null}
					</Row>
				</Form>
			) : null}
		</Card>
	);
}

export default User;
