import { Modal, Form, Input, Select, Upload, Button } from "antd";
import { useImmer } from "use-immer";
import {
	UploadOutlined,
	FileDoneOutlined,
	FilePdfFilled,
} from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";
import PartnerSearch from "components/PartnerSearch";

function ModalView({ visible, refetch, data, id, close }) {
	const [state, setState] = useImmer({});


	const [save, { loading }] = useMutation(gql`
		mutation Tutorial($input: Tutorial_Input!, $id: String) {
			Tutorial(input: $input, id: $id)
		}
	`);

	useEffect(() => {
		if (visible && data) setState({ ...data });
	}, [visible]);

	const onSubmit = async () => {
		if (!state.title)
			return Modal.error({
				content: "Por favor, informe um título.",
			});

		if (!state.status)
			return Modal.error({
				content: "Por favor, informe um status.",
			});

		try {
			const { partner_name, ...payload } = state;

			const res = await save({
				variables: {
					input: {
						...payload,
					},
					id: id || null,
				},
			});

			if (res.data.Tutorial) {
				close();
				refetch();
				setState({});
			} else {
				Modal.error({
					content: "Não foi possível salvar no momento.",
				});
			}
		} catch (error) {
			Modal.error({
				content: "Não foi possível salvar no momento.",
			});
		}
	};

	return (
		<Modal
			title="Tutorial"
			visible={visible}
			onCancel={() => {
				close();
				setState({});
			}}
			footer={[
				<Button key="back" onClick={close} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={onSubmit}
				>
					Enviar
				</Button>,
			]}
		>
			<Form layout="vertical">
				<Form.Item label="Titulo">
					<Input
						value={state.title}
						onChange={(e) => {
							setState((d) => {
								d.title = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="Descrição">
					<Input.TextArea
						value={state.description}
						onChange={(e) => {
							setState((d) => {
								d.description = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="Tipo">
					<Select
						value={state.type}
						onChange={(e) => {
							setState((d) => {
								d.type = e;
							});
						}}
					>
						<Select.Option value="1">YouTube</Select.Option>
						<Select.Option value="2">Arquivo Vídeo</Select.Option>
						<Select.Option value="3">Arquivo PDF</Select.Option>
					</Select>
				</Form.Item>
				{["3", "2"].includes(state.type) ? (
					<Form.Item label={"Arquivo"}>
						<div
							style={{
								display: "flex",
								gap: "10px",
							}}
						>
							<Upload
								maxCount={1}
								accept={
									state.type === "2" ? ".mp4" : ".pdf,.gif,.jpg,.jpeg,.png,.mp4"
								}
								beforeUpload={() => false}
								onChange={(e) => {
									setState((d) => {
										d.file = e.file;
									});
								}}
							>
								<Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
							</Upload>

							{state.url && (
								<Button
									type="primary"
									onClick={() => {
										window.open(state.url);
									}}
								>
									Ver
								</Button>
							)}
						</div>
					</Form.Item>
				) : null}
				{state.type === "1" ? (
					<Form.Item label={"URL"}>
						<Input
							value={state.url}
							onChange={(e) => {
								setState((d) => {
									d.url = e.target.value;
								});
							}}
						/>
					</Form.Item>
				) : null}
					<Form.Item label={"Ordem"}>
						<Input
							type="number"
							value={state.order}
							onChange={(e) => {
								setState((d) => {
									d.order = parseInt(e.target.value);
								});
							}}
						/>
					</Form.Item>
		

				<Form.Item label="Status">
					<Select
						value={state.status}
						onChange={(e) => {
							setState((d) => {
								d.status = e;
							});
						}}
					>
						<Select.Option value="1">Inativo</Select.Option>
						<Select.Option value="2">Ativo</Select.Option>
						<Select.Option value="3">Rascunho</Select.Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default ModalView;
