import { Button, DatePicker, Layout, Table, Typography } from "antd";

const { Content } = Layout;
const { Title } = Typography;

function UAUList() {
	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Relatórios / Cora</Title>
				</div>

				<div
					style={{
						padding: "10px 0",
					}}
				>
					<DatePicker.RangePicker
						style={{
							width: "100%",
						}}
						
					/>
				</div>

				<div>
					<Table
						columns={[
							{
								dataIndex: "name",
								key: "name",
								title: "Nome",
							},
							{
								dataIndex: "cnpj",
								key: "cnpj",
								title: "CNPJ",
							},
							{
								dataIndex: "date",
								key: "date",
								title: "Data",
							},
							{
								dataIndex: "status",
								key: "status",
								title: "Status",
							},
							{
								dataIndex: "download",
								key: "download",
								title: "Download",
								width: 100,
								render: () => {
									return (
										<Button>Baixar</Button>		
									)
								}
							},
						]}
						dataSource={[
							{
								name: "João da Silva",
								cnpj: "64.858.371/0001-91",
								date: "20/05/2024",
								status: "Ativo",
							},
						]}
					/>
				</div>
			</Content>
		</Layout>
	);
}

export default UAUList;
