import { useEffect, useState } from "react";
import {
	Layout,
	Typography,
	Drawer,
	Button,
	Form,
	Row,
	Col,
	Modal,
	Select,
	Input,
	Table,
	Tooltip,
	Tag,
} from "antd";
import InputNumberView from "components/InputNumber";

import { EditOutlined, FileOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";
import { gql, useMutation, useQuery } from "@apollo/client";

const { Content } = Layout;
const { Title } = Typography;

function Detail({ visible, close, data, id, refetch }) {
	const [state, setState] = useImmer({});

	useEffect(() => {
		if(visible && data) {
			console.log(data);
			setState({
				...data
			})
		}
		if(!visible) {
			setState({});
		}
	}, [visible, data]);
	

	const [save, { loading }] = useMutation(
		gql`
		mutation Plan($input: PlanInput!, $id: String) {
			Plan(input: $input, id: $id) 
		}
	`,
		{
			onCompleted: (res) => {
				console.log(res);

				if (res.Plan) {
					Modal.success({
						content: "Plano atualizado com sucesso",
					});
					close();
					refetch();
				} else {
					Modal.error({
						content: "Não foi possível salvar no momento.",
					});
				}
			},
			onError: (e) => {
				Modal.error({
					content: "Não foi possível salvar no momento.",
				});
			},
		}
	);

	return (
		<Drawer
			title={"Plano"}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
		>
			<Form layout="vertical">
				<Row gutter={20}>
					<Col span={24}>
						<Form.Item label={"Nome"}>
							<Input
								value={state.name}
								onChange={(e) => {
									setState((d) => {
										d.name = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Descrição"}>
							<Input.TextArea
								rows={5}
								value={state.description}
								onChange={(e) => {
									setState((d) => {
										d.description = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Serviço"}>
							<Select
								mode="multiple"
								value={state.services}
								onChange={(e) => {
									setState((d) => {
										d.services = e;
									});
								}}
							>
								<Select.Option value={"1"}>Serviço 1</Select.Option>
								<Select.Option value={"2"}>Serviço 2</Select.Option>
								<Select.Option value={"3"}>Serviço 3</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Numero de Meses"}>
							<Input
								type="number"
								value={state.months}
								onChange={(e) => {
									setState((d) => {
										d.months = parseInt(e.target.value);
									});
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Numero de parcelas"}>
							<Input
								type="number"
								value={state.installments}
								onChange={(e) => {
									setState((d) => {
										d.installments = parseInt(e.target.value);
									});
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Valor mensal"}>
							<InputNumberView
								value={state.value}
								onChange={(e) => {
									setState((d) => {
										d.value = e;
									});
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Status"}>
							<Select
								value={state.status}
								onChange={(e) => {
									setState((d) => {
										d.status = e;
									});
								}}
							>
								<Select.Option value={"active"}>Ativo</Select.Option>
								<Select.Option value={"inactive"}>Inativo</Select.Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<div className="align-right">
					<Button
						type="primary"
						loading={loading}
						onClick={async () => {
							await save({
								variables: {
									input: {
										...state,
									},
									id: id || null
								},
							});
						}}
					>
						Salvar
					</Button>
				</div>
			</Form>
		</Drawer>
	);
}

function Agenda() {
	const [detail, setDetail] = useState({
		visible: false,
	});

	const { data, loading, refetch } = useQuery(
		gql`
		{
			Plans {
				_id
				name
				description
				services
				months
				installments
				value
				status
				uuid
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	console.log(data);

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					<div className="dashboard-view">
						<div className="page-title">
							<Title>Planos</Title>

							<Button
								onClick={() => {
									setDetail({
										visible: true,
									});
								}}
							>
								Adicionar
							</Button>
						</div>

						{!loading && data && (
							<Table
								dataSource={data.Plans}
								columns={[
									{
										key: "uuid",
										dataIndex: "uuid",
										title: "ID",
										width: 260,
									},
									{
										key: "name",
										dataIndex: "name",
										title: "Nome do plano",
									},
									{
										key: "name",
										dataIndex: "name",
										title: "Descrição do plano",
									},
									{
										key: "installments",
										dataIndex: "installments",
										title: "Parcelas",
									},
									{
										key: "months",
										dataIndex: "months",
										title: "Meses",
									},
									{
										key: "status",
										dataIndex: "status",
										title: "Status",
										render: (val) => {
											console.log(val);
											if (val === "active")
												return <Tag color="green">Ativo</Tag>;
											if (val === "inactive")
												return <Tag color="orange">Inativo</Tag>;
										},
									},
									{
										key: "actions",
										dataIndex: "actions",
										title: "Ações",
										width: 100,
										render: (val, row) => {
											const { _id, uuid, ...data } = row;

											return (
												<Tooltip title="Editar">
													<Button
														shape="circle"
														icon={<EditOutlined />}
														style={{ marginRight: 8 }}
														onClick={() => {
															setDetail({
																visible: true,
																data,
																id: _id,
															});
														}}
													/>
												</Tooltip>
											);
										},
									},
								]}
							/>
						)}
					</div>
				</div>

				<Detail
					{...detail}
					refetch={refetch}
					close={() => {
						setDetail({
							visible: false,
						});
					}}
				/>
			</Content>
		</Layout>
	);
}

export default Agenda;
