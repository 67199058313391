import { useState } from "react";
import {
	Layout,
	Typography,
	Button,
	Drawer,
	Input,
	Form,
	Row,
	Col,
	Select,
	Table,
	DatePicker,
} from "antd";
import InputNumber from "components/InputNumber";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

import locale from "@fullcalendar/core/locales/pt";
import dayjs from "dayjs";
import { useImmer } from "use-immer";

require("dayjs/locale/pt");

const { Content } = Layout;
const { Title } = Typography;

function Task({ visible, close, title }) {
	const [state, setState] = useImmer({});

	return (
		<Drawer
			title={"Adicionar Tarefa"}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
		>
			<Form layout="vertical">
				<Row gutter={20}>
					<Col span={24}>
						<Form.Item label={"Tarefa"}>
							<Input />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Data prevista"}>
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Data efetiva"}>
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Prioridade"}>
							<Select>
								<Select.Option>Urgente</Select.Option>
								<Select.Option>Importante</Select.Option>
								<Select.Option>Normal</Select.Option>
								<Select.Option>Algum dia</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Status"}>
							<Select>
								<Select.Option>Pendente</Select.Option>
								<Select.Option>Concluído</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Usuário designados"}>
							<Select mode="multiple">
								<Select.Option value={1}>João Santos</Select.Option>
								<Select.Option value={2}>Maria Santos</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Observações"}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Col>
				</Row>

				<div className="align-right">
					<Button type="primary">Salvar</Button>
				</div>
			</Form>
		</Drawer>
	);
}

function Tasks({ visible, close, date }) {
	const dataSource = Array.from({ length: 1 }).map((i) => {
		return {
			client: "Tarefa teste",
			dateReference: "02/2024",
			priority: "Normal",
			owner: "Admin 1, Admin 2",
		};
	});

	const columns = [
		{
			title: "Tarefa",
			dataIndex: "client",
			key: "client",
		},
		{
			title: "Data Prevista",
			dataIndex: "dateReference",
			key: "dateReference",
		},
		{
			title: "Prioridade",
			dataIndex: "priority",
			key: "priority",
		},
		{
			title: "Usuários designados",
			dataIndex: "owner",
			key: "owner",
		},
	];


	return (
		<Drawer
			title={`Tarefas - ${date}`}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
		>
			<Table
				dataSource={dataSource}
				columns={columns}
				pagination={{
					hideOnSinglePage: true,
				}}
			/>
		</Drawer>
	);
}

function Agenda() {
	const [task, setTask] = useState({
		visible: false,
		title: "",
	});

	const [tasks, setTasks] = useState({
		visible: false,
		title: "",
	});

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					<div className="dashboard-view">
						<div className="page-title">
							<Title>Agenda</Title>
							<Button
								type="primary"
								onClick={() => {
									setTask({
										visible: true,
									});
								}}
							>
								Adicionar tarefa
							</Button>
						</div>

						<FullCalendar
							plugins={[dayGridPlugin, interactionPlugin]}
							locale={locale}
							editable
							selectable
							selectMirror
							dayMaxEvents
							weekends
							events={[
								{
									title: 'Tarefa teste',
									start: new Date(),
									end: new Date(),
									allDay: false,
									color: '#4ed87a'
							  },
							]}
							select={(e) => {
								console.log(e);
								setTasks({
									visible: true,
									date: dayjs(e.start)
										.locale("pt")
										.format("DD [de] MMMM [de] YYYY"),
								});
							}}
						/>

						<Task
							close={() => {
								setTask({
									visible: false,
								});
							}}
							{...task}
						/>
						<Tasks
							close={() => {
								setTasks({
									visible: false,
								});
							}}
							{...tasks}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	);
}

export default Agenda;
