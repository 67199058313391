import { useState } from "react";
import {
	Layout,
	Typography,
	Button,
	Drawer,
	Table,
	Input,
	Form,
	Tabs,
	Row,
	Col,
	Badge,
	Select,
	Checkbox,
	DatePicker,
	Space,
} from "antd";
import Modal from "./Modal";
import InputNumber from "components/InputNumber";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

import locale from "@fullcalendar/core/locales/pt";
import dayjs from "dayjs";
import { useImmer } from "use-immer";

require("dayjs/locale/pt");

const { Content } = Layout;
const { Title } = Typography;

function Task({ visible, close, title }) {
	const [state, setState] = useImmer({});

	return (
		<Drawer
			title={"Adicionar Tarefa"}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
		>
			<Form layout="vertical">
				<Row gutter={20}>
					<Col span={24}>
						<Form.Item label={"Tarefa"}>
							<Input />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Data prevista"}>
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Data efetiva"}>
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Prioridade"}>
							<Select>
								<Select.Option>Urgente</Select.Option>
								<Select.Option>Importante</Select.Option>
								<Select.Option>Normal</Select.Option>
								<Select.Option>Algum dia</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Status"}>
							<Select>
								<Select.Option>Pendente</Select.Option>
								<Select.Option>Concluído</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Usuário designados"}>
							<Select mode="multiple">
								<Select.Option value={1}>João Santos</Select.Option>
								<Select.Option value={2}>Maria Santos</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Observações"}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Col>
				</Row>

				<div className="align-right">
					<Button type="primary">Salvar</Button>
				</div>
			</Form>
		</Drawer>
	);
}

function Tasks({ visible, close, date }) {
	const dataSource = Array.from({ length: 1 }).map((i) => {
		return {
			client: "Tarefa teste",
			dateReference: "02/2024",
			priority: "Normal",
			owner: "Admin 1, Admin 2",
		};
	});

	const columns = [
		{
			title: "Tarefa",
			dataIndex: "client",
			key: "client",
		},
		{
			title: "Data Prevista",
			dataIndex: "dateReference",
			key: "dateReference",
		},
		{
			title: "Prioridade",
			dataIndex: "priority",
			key: "priority",
		},
		{
			title: "Usuários designados",
			dataIndex: "owner",
			key: "owner",
		},
	];

	return (
		<Drawer
			title={`Tarefas - ${date}`}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
		>
			<Table
				dataSource={dataSource}
				columns={columns}
				pagination={{
					hideOnSinglePage: true,
				}}
			/>
		</Drawer>
	);
}

function Protocol({ visible, close, title }) {
	const [state, setState] = useImmer({});

	return (
		<Drawer
			title={"Adicionar Protocolo"}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
		>
			<Form layout="vertical">
				<Row gutter={20}>
					<Col span={12}>
						<Form.Item label={"Obrigação"}>
							<Select>
								<Select.Option>Selecione</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Cliente"}>
							<Select>
								<Select.Option>Selecione</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Competência"}>
							<DatePicker style={{ width: "100%" }} picker="month" />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Data de vencimento"}>
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Valor"}>
							<InputNumber />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Destino"}>
							<Select>
								<Select.Option>E-mail</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Status"}>
							<Select>
								<Select.Option>Selecione</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Observações internas"}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Informação ao destinatário"}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Col>
				</Row>

				<div className="align-right">
					<Button type="primary">Salvar</Button>
				</div>
			</Form>
		</Drawer>
	);
}

function Obligation({ visible, close, title }) {
	const [state, setState] = useImmer({});

	return (
		<Drawer
			title={"Adicionar Obrigação"}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
		>
			<Form layout="vertical">
				<Form.Item label={"Nome da obrigação"}>
					<Input />
				</Form.Item>

				<Row gutter={20}>
					<Col span={12}>
						<Form.Item label={"Tipo da obrigação"}>
							<Select>
								<Select.Option>Cadastral</Select.Option>
								<Select.Option>Pagamento</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={"Apelido da obrigação"}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={18}>
						<Form.Item label={"Departamento da obrigação"}>
							<Select mode="multiple" allowClear>
								<Select.Option value={"Contábil"}>Contábil</Select.Option>
								<Select.Option value={"Financeiro"}>Financeiro</Select.Option>
								<Select.Option value={"Fiscal"}>Fiscal</Select.Option>
								<Select.Option value={"Pessoal"}>Pessoal</Select.Option>
								<Select.Option value={"Societário"}>Societário</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label={"Frequencia"}>
							<Select>
								<Select.Option>Anual</Select.Option>
								<Select.Option>Mensal</Select.Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={8}>
						<Form.Item>
							<Checkbox onChange={() => {}}>Entrega somente física</Checkbox>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item>
							<Checkbox onChange={() => {}}>Controle de vencimento</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Form.Item
					label={"Incluir observação padrão ao cliente sobre a obrigação"}
				>
					<Input.TextArea rows={10} />
				</Form.Item>

				<div className="align-right">
					<Button type="primary">Salvar</Button>
				</div>
			</Form>
		</Drawer>
	);
}

function Detail({ visible, close, title }) {
	const [state, setState] = useImmer({});

	const dataSource = Array.from({ length: 1 }).map((i) => {
		return {
			client: "Nome do Cliente",
			dateReference: "02/2024",
			dateDue: "08/03/2024",
			owner: "João Andrade",
		};
	});

	const columns = [
		{
			title: "Cliente",
			dataIndex: "client",
			key: "client",
		},
		{
			title: "Competência",
			dataIndex: "dateReference",
			key: "dateReference",
		},
		{
			title: "Vencimento",
			dataIndex: "dateDue",
			key: "dateDue",
		},
		{
			title: "Responsável",
			dataIndex: "owner",
			key: "owner",
		},
	];

	const list = [
		{
			title: "Encerramento competência ISS dia 10",
			badge: "green",
			number: 8,
		},
		{
			title: "ISS dia 10",
			badge: "green",
			number: 2,
		},
		{
			title: "ISS Retido dia 10",
			badge: "green",
			number: 3,
		},
		{
			title: "ST CSRF 5952",
			badge: "green",
			number: 1,
		},
		{
			title: "ISS dia 15",
			badge: "green",
			number: 6,
		},
		{
			title: "SR Memória de Cálculo Retidos",
			badge: "green",
			number: 10,
		},
	];

	return (
		<Drawer
			title={title ?? "Detalhe"}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
			className="calendar-detail"
		>
			<Tabs className="full-tabs">
				<Tabs.TabPane tab="Por cliente" key="item-1">
					<div className="calendar-detail-clients">
						<Table
							dataSource={dataSource}
							columns={columns}
							pagination={{
								hideOnSinglePage: true,
							}}
							rowSelection={{
								type: "checkbox",
							}}
						/>
						<div className="reason">
							<Form layout="vertical">
								<Form.Item label="Aplicar baixa justificada para os registros selecionados">
									<Input.TextArea
										value={state.reason}
										onChange={(e) =>
											setState((d) => {
												d.reason = e.target.value;
											})
										}
										placeholder="Digite a justificativa da baixa"
										autoSize={{ minRows: 3, maxRows: 5 }}
									/>
								</Form.Item>
								<div className="align-right">
									<Button type="primary">Enviar</Button>
								</div>
							</Form>
						</div>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Por atividade" key="item-2">
					<h3>Pendente</h3>
					{list.map((item, index) => {
						return (
							<Row
								gutter={10}
								style={{
									borderBottom: "1px solid #e5e5e5",
									padding: "10px 0",
								}}
							>
								<Col>
									<Badge color={item.badge ?? "#777"} />
								</Col>
								<Col>{item.title}</Col>
							</Row>
						);
					})}

					<br />

					<h3>Concluído</h3>
					{list.map((item, index) => {
						return (
							<Row
								gutter={10}
								style={{
									borderBottom: "1px solid #e5e5e5",
									padding: "10px 0",
								}}
							>
								<Col>
									<Badge color={"#777"} />
								</Col>
								<Col>{item.title}</Col>
							</Row>
						);
					})}
				</Tabs.TabPane>
			</Tabs>
		</Drawer>
	);
}

function Agenda() {
	const [detail, setDetail] = useState({
		visible: false,
		title: "",
	});

	const [obligation, setObligation] = useState({
		visible: false,
	});

	const [protocol, setProtocol] = useState({
		visible: false,
	});

	const [task, setTask] = useState({
		visible: false,
		title: "",
	});

	const [selectedDate, setSelectedDate] = useState(null);

	const start = dayjs().toISOString();
	const end = dayjs().add(2, "hours").toISOString();

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					<div className="dashboard-view">
						<div className="page-title">
							<Title>Agenda</Title>

							<Button
								type="primary"
								onClick={() => {
									setTask({
										visible: true,
									});
								}}
							>
								Adicionar tarefa
							</Button>

							<Task
								close={() => {
									setTask({
										visible: false,
									});
								}}
								{...task}
							/>
						</div>

						<Tabs>
							<Tabs.TabPane tab="Calendário" key="calendar">
								<FullCalendar
									plugins={[dayGridPlugin, interactionPlugin]}
									locale={locale}
									editable
									selectable
									selectMirror
									dayMaxEvents
									weekends
									select={(e) => {
										console.log(e);
										setDetail({
											visible: true,
											title: dayjs(e.start)
												.locale("pt")
												.format("DD [de] MMMM [de] YYYY"),
										});
									}}
								/>

								<Detail
									close={() => {
										setDetail({
											visible: false,
										});
									}}
									{...detail}
								/>
							</Tabs.TabPane>
							<Tabs.TabPane tab="Obrigações" key="obligations">
								<div className="align-right" style={{ marginBottom: 20 }}>
									<Button
										type="primary"
										onClick={() => {
											setObligation({
												visible: true,
											});
										}}
									>
										Adicionar
									</Button>
									<Obligation
										{...obligation}
										close={() => {
											setObligation({
												visible: false,
											});
										}}
									/>
								</div>
								<Table
									dataSource={Array.from({ length: 1 }).map((i) => {
										return {
											name: "Acompanhamento Parcelamento Federal",
											tipo: "Cadastral",
											department: "Fiscal",
											fisical: "Sim",
											dueControl: "Sim",
										};
									})}
									pagination={{
										pageSize: 20,
									}}
									columns={[
										{
											dataIndex: "name",
											key: "name",
											title: "Obrigação",
										},
										{
											dataIndex: "type",
											key: "type",
											title: "Tipo",
										},
										{
											dataIndex: "department",
											key: "department",
											title: "Departamento",
										},
										{
											dataIndex: "fisical",
											key: "fisical",
											title: "Entrega fisica",
										},
										{
											dataIndex: "dueControl",
											key: "dueControl",
											title: "Controle vencimento",
										},
										{
											dataIndex: "actions",
											key: "actions",
											title: "",
											width: 200,
											render: () => {
												return (
													<div
														style={{
															display: "flex",
															gap: 10,
														}}
													>
														<Button>Editar</Button>
														<Button>Remover</Button>
													</div>
												);
											},
										},
									]}
								/>
							</Tabs.TabPane>
							<Tabs.TabPane tab="Protocolos" key="protocolos">
								<div className="align-right">
									<Button
										type="primary"
										onClick={() => {
											setProtocol({
												visible: true,
											});
										}}
									>
										Adicionar
									</Button>
									<Protocol
										{...protocol}
										close={() => {
											setProtocol({
												visible: false,
											});
										}}
									/>
								</div>
								<Row gutter={20} style={{ marginBottom: 20, marginTop: 10 }}>
									<Col span={8}>
										<Select
											style={{ width: "100%" }}
											placeholder={"Selecione um cliente"}
										></Select>
									</Col>
									<Col span={8}>
										<Select
											style={{ width: "100%" }}
											placeholder={"Selecione um tipo"}
										></Select>
									</Col>
									<Col span={8}>
										<DatePicker.RangePicker style={{ width: "100%" }} />
									</Col>
								</Row>

								<Table
									dataSource={Array.from({ length: 1 }).map((i) => {
										return {
											name: "Acompanhamento Parcelamento Federal",
											type: "Cadastral",
											client: "André Santos",
											date: "08/03/2024",
										};
									})}
									pagination={{
										pageSize: 20,
									}}
									columns={[
										{
											dataIndex: "name",
											key: "name",
											title: "Obrigação",
										},
										{
											dataIndex: "type",
											key: "type",
											title: "Tipo",
										},
										{
											dataIndex: "client",
											key: "client",
											title: "Cliente",
										},
										{
											dataIndex: "date",
											key: "date",
											title: "Data",
										},
										{
											dataIndex: "actions",
											key: "actions",
											title: "",
											width: 200,
											render: () => {
												return (
													<div
														style={{
															display: "flex",
															gap: 10,
														}}
													>
														<Button>Editar</Button>
														<Button>Remover</Button>
													</div>
												);
											},
										},
									]}
								/>
							</Tabs.TabPane>
						</Tabs>
					</div>
				</div>
			</Content>
		</Layout>
	);
}

export default Agenda;
