import { Layout, Typography, Button, Table, Badge, Tag } from "antd";

import dayjs from "dayjs";
import { EditOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";
import Tutorial from "./Modal";
import { gql, useQuery } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

const { Content } = Layout;
const { Title } = Typography;

function Tutorials() {
	const [modal, setModal] = useImmer({
		visible: false,
	});

	const { data, loading, refetch, error } = useQuery(gql`
		{
			Tutorials {
				_id
				title
				description
				type
				source
				order
				url
				status
				partner_id
				partner_name
			}
		}
	`);

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Tutoriais</Title>
					<div>
						<Button
							type="primary"
							onClick={() => {
								setModal({ visible: true });
							}}
						>
							Novo
						</Button>
					</div>
				</div>

				<div className="dashboard">
					<div>
						{loading ? <Loading /> : null}
						{!loading && error ? <Error refetch={() => refetch()} /> : null}
						{!loading && !error && (
							<Table
								rowKey="_id"
								dataSource={data.Tutorials}
								pagination={{
									hideOnSinglePage: true,
								}}
								columns={[
									{
										key: "title",
										dataIndex: "title",
										title: "Titulo",
									},
									{
										key: "type",
										dataIndex: "type",
										title: "Tipo",
										render: (value) => {
											if (value === "1") return "Youtube";
											if (value === "2") return "Arquivo Video";
											if (value === "3") return "Arquivo PDF";
										},
									},
									{
										key: "order",
										dataIndex: "order",
										title: "Ordem",
										render: (value) => value || '-',
									},
									{
										key: "source",
										dataIndex: "source",
										title: "Fonte",
										render: (value) => {
											if (value === "admin") return <Tag>Admin</Tag>;
											if (value === "partner") return <Tag>Parceiro</Tag>;
										},
									},
									{
										key: "url",
										dataIndex: "url",
										title: "URL",
										render: (value) => {
											if(!value) return "-";
											return (
												<Button
													onClick={() => {
														window.open(value);
													}}
												>
													Abrir
												</Button>
											);
										},
									},
									{
										key: "status",
										dataIndex: "status",
										title: "Status",
										render: (value) => {
											if (value === "1")
												return <Tag color="orange">Inativo</Tag>;
											if (value === "2") return <Tag color="green">Ativo</Tag>;
											if (value === "3") return <Tag>Rascunho</Tag>;
										},
									},
									{
										key: "action",
										dataIndex: "action",
										title: "",
										width: 100,
										render: (value, row) => {
											const { _id, ...data } = row;

											return (
												<Button
													onClick={() => {
														setModal({
															visible: true,
															id: _id ?? null,
															data: data,
														});
													}}
												>
													Editar <EditOutlined />
												</Button>
											);
										},
									},
								]}
							/>
						)}
					</div>

					<Tutorial
						{...modal}
						refetch={refetch}
						close={() => {
							setModal({ visible: false });
						}}
					/>
				</div>
			</Content>
		</Layout>
	);
}

export default Tutorials;
