import React, {lazy} from "react";
import { Route, Switch } from "react-router-dom";
// Scenes Imports

import Dashboard from "./dashboard";
import Services from "./services";
import Tickets from "./tickets";
import * as Users from "./users";
import * as MEI from "./mei";
import DAS from "./das";
import Invoices from "./invoices";
import Admins from "./admins";
import Refers from "./refers";
import UAU from "./uau";
import Plans from "./plans";
import * as Charges from "./charges";
import Schedules from "./schedules";
import Agenda from "./agenda";
import Departments from "./departments";
import ReceivedDocs from "./received-documents";
import SentDocs from "./sent-documents";
import * as Companies from "./companies";
import * as Partners from "./partners";
import Cora from "./reports/cora";
import ActiveDebts from "./activeDebts";
import Tutorials from "./tutorials";
import Soluti from "./reports/soluti";
import Documents from "./documents";

import NotFound from "./not-found";

// Routes Config

const routes = [
	{
		path: "/",
		exact: true,
		component: Dashboard,
	},
	{
		path: "/services",
		exact: true,
		component: Services,
	},
	{
		path: "/users",
		exact: true,
		component: Users.List,
	},
	{
		path: "/abertura-mei",
		exact: true,
		component: MEI.List,
	},
	{
		path: "/user/:id",
		exact: true,
		component: Users.User,
	},
	{
		path: "/user/logs/:id",
		exact: true,
		component: Users.Logs,
	},
	{
		path: "/create-user",
		exact: true,
		component: Users.New,
	},
	{
		path: "/tickets",
		exact: true,
		component: Tickets,
	},
	{
		path: "/das",
		exact: true,
		component: DAS,
	},
	{
		path: "/admins",
		exact: true,
		component: Admins,
	},
	{
		path: "/companies",
		exact: true,
		component: Companies.List,
	},
	{
		path: "/company/:id",
		exact: true,
		component: Companies.View,
	},
	{
		path: "/partners",
		exact: true,
		component: Partners.List,
	},
	{
		path: "/partners/groups",
		exact: true,
		component: Partners.Groups,
	},
	{
		path: "/partner/:id",
		exact: true,
		component: Partners.View,
	},
	{
		path: "/partner/:id",
		exact: true,
		component: Partners.View,
	},
	{
		path: "/invoices",
		exact: true,
		component: Invoices,
	},
	{
		path: "/refers",
		exact: true,
		component: Refers,
	},
	{
		path: "/schedules",
		exact: true,
		component: Schedules,
	},
	{
		path: "/uau",
		exact: true,
		component: UAU,
	},
	{
		path: "/calendar",
		exact: true,
		component: Agenda,
	},
	{
		path: "/charges",
		exact: true,
		component: Charges.List,
	},
	{
		path: "/plans",
		exact: true,
		component: Plans,
	},
	{
		path: "/received-documents",
		exact: true,
		component: ReceivedDocs
	},
	{
		path: "/sent-documents",
		exact: true,
		component: SentDocs
	},
	{
		path: "/reports/cora",
		exact: true,
		component: Cora
	},
	{
		path: "/reports/ideia",
		exact: true,
		component: Soluti
	},
	{
		path: "/departments",
		exact: true,
		component: Departments
	},
	{
		path: "/active-debts",
		exact: true,
		component: ActiveDebts
	},
	{
		path: "/documents",
		exact: true,
		component: Documents
	},
	{
		path: "/tutorials",
		exact: true,
		component: Tutorials
	},
];

// Routes Functions

function RouteWithSubRoutes(route) {
	return (
		<Route
			path={route.path}
			exact={route.exact ? true : false}
			render={(props) => <route.component {...props} routes={route.routes} />}
		/>
	);
}

function RouteConfig() {
	let returnArray = [];
	routes.map((route, i) => {
		let routeObj = {
			...route,
		};
		returnArray.push(<RouteWithSubRoutes key={i} {...routeObj} />);
	});

	//return returnArray;

	return (
		<Switch>
			{returnArray}
			<Route component={NotFound} />
		</Switch>
	);
}

export default RouteConfig;
