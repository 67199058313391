import {
	Layout,
	Table,
	Tag,
	Typography,
	Row,
	Col,
	Select,
	Form,
	DatePicker,
} from "antd";
const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

function Agenda() {
	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					<div className="dashboard-view">
						<div className="page-title">
							<Title>Documentos Recebidos</Title>
						</div>

						<div>
							<Row gutter={20}>
								<Col span={6}>
									<Form.Item label="Tipo">
										<Select value={""} onChange={(e) => {}}>
											<Option value="all">Todos</Option>
											<Option value="1">Cadastral</Option>
											<Option value="2">Declaração</Option>
											<Option value="3">Pagamento</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item label="Status">
										<Select value={""} onChange={(e) => {}}>
											<Option value="all">Todos</Option>
											<Option value="1">Em aberto</Option>
											<Option value="1">Agendados</Option>
											<Option value="1">Pagos</Option>
											<Option value="2">Vencido</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Período"}>
										<DatePicker.RangePicker
											style={{
												width: "100%",
											}}
											format={"DD/MM/YYYY"}
										/>
									</Form.Item>
								</Col>
							</Row>
						</div>

						<Table
							columns={[
								{
									key: "type",
									dataIndex: "type",
									title: "Tipo",
								},
								{
									key: "document",
									dataIndex: "document",
									title: "Documento",
								},
								{
									key: "competence",
									dataIndex: "competence",
									title: "Competência",
								},
								{
									key: "dueDate",
									dataIndex: "dueDate",
									title: "Vencimento",
								},
								{
									key: "value",
									dataIndex: "value",
									title: "Valor",
								},
								{
									key: "status",
									dataIndex: "status",
									title: "Status",
									render: (e) => {
										if (e === "due") {
											return <Tag color="red">Vencido</Tag>;
										}
									},
								},
							]}
							dataSource={[
								{
									type: "Guia",
									document: "ISS dia 20",
									competence: "Maio 2024",
									dueDate: "20/05/2024",
									value: "R$ 342,98",
									status: "due",
								},
							]}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	);
}

export default Agenda;
