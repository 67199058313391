import {
	Layout,
	Table,
	Typography,
	Select,
	Tooltip,
	Button,
	Col,
	Row,
	Form,
	Input,
	Drawer,
} from "antd";

import { EditOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

function Detail({ visible, close, data, id, refetch }) {
	return (
		<Drawer
			title={"Departamento"}
			placement="right"
			onClose={() => {
				close();
			}}
			visible={visible}
			width={600}
		>
			<Form layout="vertical">
				<Row gutter={20}>
					<Col span={24}>
						<Form.Item label={"Nome"}>
							<Input />
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Responsáveis"}>
							<Select mode="multiple">
								<Select.Option value={"1"}>Admin 1</Select.Option>
								<Select.Option value={"2"}>Admin 2</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label={"Status"}>
							<Select onChange={(e) => {}} style={{ width: "100%" }}>
								<Select.Option value={"active"}>Ativo</Select.Option>
								<Select.Option value={"inactive"}>Inativo</Select.Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<div className="align-right">
					<Button type="primary" onClick={async () => {}}>
						Salvar
					</Button>
				</div>
			</Form>
		</Drawer>
	);
}

function Agenda() {
	const [detail, setDetail] = useImmer({
		visible: false,
	});

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					<div className="dashboard-view">
						<div className="page-title">
							<Title>Departamentos</Title>
							<Button
								type="primary"
								onClick={() => {
									setDetail({
										visible: true,
									});
								}}
							>
								Adicionar
							</Button>
						</div>

						<Table
							columns={[
								{
									key: "name",
									dataIndex: "name",
									title: "Nome",
								},
								{
									key: "assigned",
									dataIndex: "assigned",
									title: "Responsáveis",
								},
								{
									key: "status",
									dataIndex: "status",
									title: "Status",
								},
								{
									key: "actions",
									dataIndex: "actions",
									title: "Ações",
									width: 100,
									render: (val, row) => {
										const { _id, uuid, ...data } = row;

										return (
											<Tooltip title="Editar">
												<Button
													shape="circle"
													icon={<EditOutlined />}
													style={{ marginRight: 8 }}
													onClick={() => {
														setDetail({
															visible: true,
														});
													}}
												/>
											</Tooltip>
										);
									},
								},
							]}
							dataSource={[
								{
									name: "Contábil",
									assigned: "Admin 1, Admin 2",
									status: "Ativo",
								},
								{
									name: "Financeiro",
									assigned: "Admin 1, Admin 2",
									status: "Ativo",
								},
								{
									name: "Fiscal",
									assigned: "Admin 1, Admin 2",
									status: "Ativo",
								},
								{
									name: "Pessoal",
									assigned: "Admin 1, Admin 2",
									status: "Ativo",
								},
								{
									name: "Societário",
									assigned: "Admin 1, Admin 2",
									status: "Ativo",
								},
							]}
						/>
					</div>
				</div>

				<Detail
					{...detail}
					close={() => {
						setDetail({
							visible: false,
						});
					}}
				/>
			</Content>
		</Layout>
	);
}

export default Agenda;
