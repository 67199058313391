import { useState, useEffect, useReducer, useRef } from "react";
import * as Queries from "./Queries";
import { useLazyQuery, useMutation, gql } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

import PartnerSearch from "components/PartnerSearch";
import BusinessStatus from "scenes/users/util/BusinessStatus";
import { EditOutlined, FileOutlined } from "@ant-design/icons";

import InputNumber from "components/InputNumber";

import MaskedInput from "antd-mask-input";
import {
	Layout,
	Table,
	Typography,
	Button,
	DatePicker,
	Badge,
	Select,
	AutoComplete,
	Form,
	Input,
	Descriptions,
	Popover,
	Tag,
	Tooltip,
	Drawer,
	Row,
	Col,
	Tabs,
} from "antd";
import { statuses } from "./Misc";
import moment from "moment";

import { useDebounce } from "@react-hook/debounce";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const AUTOCOMPLETE_QUERY = gql`
	query BusinessAutocomplete($term: String!, $type: String!) {
		BusinessAutocomplete(term: $term, type: $type) {
			_id,
			company_name,
			trade_name
		}
	}
`;

function Detail({ visible, close, data }) {
	console.log(data);

	const [state, setState] = useState({});

	return (
		<Drawer
			title={"Sobre"}
			placement="right"
			onClose={() => {
				close();
			}}
			width={800}
			visible={visible}
		>
			{data && (
				<>
					<Descriptions title="Sobre o cliente" bordered>
						<Descriptions.Item label="Nome" span={3}>
							{data.name ?? ""}
						</Descriptions.Item>
						<Descriptions.Item label="E-mail" span={3}>
							{data.email ?? ""}
						</Descriptions.Item>
						<Descriptions.Item label="Telefone" span={3}>
							{data.phone ?? ""}
						</Descriptions.Item>
						<Descriptions.Item label="CNPJ" span={3}>
							{data?.cnpj?.length ? data.cnpj[0] : ""}
						</Descriptions.Item>
					</Descriptions>

					<div style={{ marginTop: 20 }}>
						<Tabs>
							<Tabs.TabPane tab="Informações" key="info">
								<Form layout="vertical">
									<Row gutter={20}>
										<Col span={8}>
											<Form.Item label={"Status"}>
												<Select
													value={state?.contract_status}
													onChange={(e) => {
														setState((d) => {
															d.contract_status = e;
														});
													}}
												>
													<Select.Option value="">Qualquer</Select.Option>
													<Select.Option value="0">Expirado</Select.Option>
													<Select.Option value="1">Ativo</Select.Option>
													<Select.Option value="2">
														Não cadastrou/requisitou empresa
													</Select.Option>
													<Select.Option value="3">
														Aguardando Abertura
													</Select.Option>
													<Select.Option value="4">Inativo</Select.Option>
													<Select.Option value="5">Cancelado</Select.Option>
												</Select>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item label={"Data Vencimento Fatura"}>
												<Select
													value={state?.invoice_monthly_date}
													onChange={(e) => {
														setState((d) => {
															d.invoice_monthly_date = e.toString();
														});
													}}
												>
													{Array.from(Array(32).keys()).map((item) => {
														if (item !== 0) {
															return (
																<Select.Option
																	key={`invoice_monthly_date_${item}`}
																	value={item}
																>
																	{item}
																</Select.Option>
															);
														}
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item label={"Forma de Cobrança"}>
												<Select
													value={state?.payment_method}
													onChange={(e) => {
														setState((d) => {
															d.payment_method = e;
														});
													}}
												>
													<Select.Option value={"boleto"}>Boleto</Select.Option>
													<Select.Option value={"card"}>Cartão</Select.Option>
													<Select.Option value={"pix"}>PIX</Select.Option>
												</Select>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item label={"Valor Cheio"}>
												<InputNumber
													value={state?.amount}
													onChange={(e) => {
														setState((d) => {
															d.amount = e;
														});
													}}
												/>
											</Form.Item>
										</Col>
										<Col span={16}>
											<Row gutter={20}>
												<Col span={12}>
													<Form.Item label={"Valor Provisório e período"}>
														<InputNumber
															value={state?.amount_temporary}
															onChange={(e) => {
																setState((d) => {
																	d.amount_temporary = e;
																});
															}}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label={" "}>
														<DatePicker.RangePicker
															style={{
																width: "100%",
															}}
															format={"DD/MM/YYYY"}
															value={[
																state?.amount_temporary_period_start,
																state?.amount_temporary_period_end,
															]}
															onChange={(e) => {
																setState((d) => {
																	d.amount_temporary_period_start = e[0];
																	d.amount_temporary_period_end = e[1];
																});
															}}
														/>
													</Form.Item>
												</Col>
											</Row>
										</Col>
									</Row>

									<div style={{ textAlign: "right" }}>
										<Button type="primary" onClickCapture={() => {}}>
											Salvar
										</Button>
									</div>
								</Form>
							</Tabs.TabPane>
							<Tabs.TabPane tab="Faturas" key="invoices">
								<Table
									pagination={{
										hideOnSinglePage: true,
									}}
									dataSource={[]}
									columns={[
										{
											key: "invoice_duedate",
											dataIndex: "invoice_duedate",
											title: "Data Vencimento",
											width: 200,
											render: (value) =>
												value ? moment(value).format("DD/MM/YYYY") : "-",
										},
										{
											key: "amount",
											dataIndex: "amount",
											title: "Valor",
											render: (value) =>
												value
													? value.toLocaleString("pt-BR", {
															style: "currency",
															currency: "BRL",
													  })
													: "-",
										},
										{
											key: "payment_status",
											dataIndex: "payment_status",
											title: "Status do Pagamento",
											render: (value) => {
												let label, color;

												switch (value) {
													case "paid":
														label = "Pago";
														color = "green";
														break;
													case "due":
														label = "A vencer";
														color = "gold";
														break;
													default:
														label = "Vencido";
														color = "red";
														break;
												}
												return <Tag color={color}>{label}</Tag>;
											},
										},
										{
											key: "invoice_status",
											dataIndex: "invoice_status",
											title: "Status Nota Fiscal",
											render: (value) => {
												let label;
												switch (value) {
													case "created":
														label = "Emitida";
														break;
													case "to_be_created":
														label = "A emitir";
														break;
													default:
														label = "Enviado contabilidade";
														break;
												}
												return <Tag>{label}</Tag>;
											},
										},

										{
											key: "file",
											dataIndex: "file",
											title: "Arquivo",
											width: 100,
											render: (value) => {
												if (value)
													return (
														<Button
															shape="circle"
															onClick={() => {
																window.open(value);
															}}
														>
															<FileOutlined />
														</Button>
													);

												return null;
											},
										},
										{
											key: "actions",
											dataIndex: "actions",
											title: "Ações",
											width: 100,
											render: (value, row) => {
												return (
													<Tooltip title="Editar">
														<Button shape="circle" onClick={() => {}}>
															<EditOutlined />
														</Button>
													</Tooltip>
												);
											},
										},
									]}
								/>

								<div style={{ textAlign: "right", marginTop: 30 }}>
									<Button type="primary" onClickCapture={() => {}}>
										Adicionar
									</Button>
								</div>
							</Tabs.TabPane>
							<Tabs.TabPane tab="Histórico de Pagamentos" key="payment-logs">
								<Table
									dataSource={[]}
									pagination={{
										hideOnSinglePage: true,
									}}
									columns={[
										{
											key: "invoice_duedate",
											dataIndex: "invoice_duedate",
											title: "Data de Vencimento",
											width: 200,
											render: (value) =>
												value ? moment(value).format("DD/MM/YYYY") : "-",
										},
										{
											key: "invoice_paymentdate",
											dataIndex: "invoice_paymentdate",
											title: "Data de Pagamento",
											width: 200,
											render: (value) =>
												value ? moment(value).format("DD/MM/YYYY") : "-",
										},

										{
											key: "amount",
											dataIndex: "amount",
											title: "Valor",
											render: (value) =>
												value
													? value.toLocaleString("pt-BR", {
															style: "currency",
															currency: "BRL",
													  })
													: "-",
										},
										{
											key: "invoice_status",
											dataIndex: "invoice_status",
											title: "Status",
											render: (value) => {
												return <Tag color="green">Pago</Tag>;
											},
										},
									]}
								/>
							</Tabs.TabPane>
						</Tabs>
					</div>
				</>
			)}
		</Drawer>
	);
}

function Users(props) {
	const mounted = useRef(false);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const [drawer, setDrawer] = useState(false);
	const [loadingPagination, setLoadingPagination] = useState(false);

	const [detail, setDetail] = useState({
		visible: false,
	});

	// AUTOCOMPLETE

	const [loadCompanyName, companyName] = useLazyQuery(AUTOCOMPLETE_QUERY, {
		fetchPolicy: "no-cache",
	});
	const [companyNameDebounce, setCompanyNameDebounce] = useDebounce(null, 500);
	useEffect(() => {
		if (mounted.current) {
			loadCompanyName({
				variables: {
					term: companyNameDebounce,
					type: "company_name",
				},
			});
		}
	}, [companyNameDebounce]);

	const [loadTradeName, tradeName] = useLazyQuery(AUTOCOMPLETE_QUERY, {
		fetchPolicy: "no-cache",
	});
	const [tradenameDebounce, setTradeNameDebounce] = useDebounce(null, 500);
	useEffect(() => {
		if (mounted.current) {
			loadTradeName({
				variables: {
					term: tradenameDebounce,
					type: "trade_name",
				},
			});
		}
	}, [tradenameDebounce]);

	const [load, { loading, data, error, refetch, called }] = useLazyQuery(
		Queries.USERS,
		{
			fetchPolicy: "no-cache",
		}
	);

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);

	const [filters, setFilters] = useState({
		status: "1",
		business_status: urlParams.get("business_status")
			? urlParams.get("business_status")
			: "",
	});

	const [pages, setPages] = useState({
		current: 1,
	});

	useEffect(() => {
		filter();
		mounted.current = true;
	}, []);

	const filter = () => {
		setPages({ current: 1 });
		load({
			variables: {
				filters,
				page: 1,
			},
		});
	};

	const hasFilters = () => {
		let has = false;
		for (var prop in filters) {
			if (prop !== "status") {
				if (filters[prop] || filters[prop] !== "") has = true;
			}
		}
		return has;
	};

	const clearFilters = () => {
		setPages({ current: 1 });
		setFilters({
			status: "1",
		});
		forceUpdate();
		load({
			variables: {
				page: 1,
			},
		});
	};

	const getFilterValue = (filters, path) => {
		return filters[path] || null;
	};

	const columns = [
		{
			title: "Nome",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (email) => email || "-",
		},
		{
			title: "CNPJs",
			dataIndex: "cnpj",
			key: "cnpj",
			render: (cnpj) => (
				<Popover
					content={
						<span>
							{cnpj.map((i) => (
								<span>
									{i}
									<br />
								</span>
							))}
						</span>
					}
					title="CNPJs"
				>
					{cnpj.length ? (
						<span>
							{cnpj[0]} {cnpj.length > 1 ? `e mais ${cnpj.length - 1}...` : ""}
						</span>
					) : (
						"-"
					)}
				</Popover>
			),
		},
		{
			title: "Empresa",
			dataIndex: "company",
			key: "company",
			render: (value, row) => row?.partner_company?.name || "-",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status) => {
				return statuses[status];
			},
		},
		{
			title: "Ações",
			dataIndex: "",
			key: "x",
			width: 80,
			render: (value, row) => {
				return (
					<div>
						<Tooltip title="Editar">
							<Button
								shape="circle"
								icon={<EditOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									setDetail({
										visible: true,
										data: row,
									});
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
	];

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Cobranças</Title>

					<div style={{ display: "flex" }}>
						{hasFilters() ? (
							<Badge dot>
								{" "}
								<Button
									style={{ marginLeft: 10 }}
									onClick={() => {
										setDrawer(true);
									}}
								>
									Filtros
								</Button>
							</Badge>
						) : (
							<Button
								style={{ marginLeft: 10 }}
								onClick={() => {
									setDrawer(true);
								}}
							>
								Filtros
							</Button>
						)}
					</div>
				</div>

				<Drawer
					title="Filtros"
					visible={drawer}
					onClose={() => setDrawer(false)}
					width={600}
				>
					<Form layout="vertical">
						<Form.Item label="Nome">
							<Input
								value={getFilterValue(filters, "name")}
								onChange={(e) => {
									const { value } = e.target;
									setFilters((state) => {
										return {
											...state,
											name: value,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="CPF">
							<MaskedInput
								mask={"111.111.111-11"}
								value={getFilterValue(filters, "cpf")}
								onChange={(e) => {
									const { value } = e.target;
									setFilters((state) => {
										return {
											...state,
											cpf: value,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="CNPJ">
							<MaskedInput
								mask={"11.111.111/1111-11"}
								value={getFilterValue(filters, "cnpj")}
								onChange={(e) => {
									const { value } = e.target;
									setFilters((state) => {
										return {
											...state,
											cnpj: value,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="E-mail">
							<Input
								value={getFilterValue(filters, "email")}
								onChange={(e) => {
									const { value } = e.target;
									setFilters((state) => {
										return {
											...state,
											email: value,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Razão Social">
							<AutoComplete
								options={
									companyName?.data?.BusinessAutocomplete?.length
										? companyName?.data?.BusinessAutocomplete.map((i) => {
												return {
													label: i.company_name,
													value: i.company_name,
												};
										  })
										: []
								}
								allowClear
								onClear={() => {
									setFilters((state) => {
										return {
											...state,
											company_name: null,
										};
									});
								}}
								value={getFilterValue(filters, "company_name")}
								onSearch={(e) => {
									setCompanyNameDebounce(e);
								}}
								onChange={(e) => {
									setFilters((state) => {
										return {
											...state,
											company_name: e,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Nome Fantasia">
							<AutoComplete
								options={
									tradeName?.data?.BusinessAutocomplete?.length
										? tradeName?.data?.BusinessAutocomplete.map((i) => {
												return {
													label: i.trade_name,
													value: i.trade_name,
												};
										  })
										: []
								}
								allowClear
								onClear={() => {
									setFilters((state) => {
										return {
											...state,
											trade_name: null,
										};
									});
								}}
								value={getFilterValue(filters, "trade_name")}
								onSearch={(e) => {
									setTradeNameDebounce(e);
								}}
								onChange={(e) => {
									setFilters((state) => {
										return {
											...state,
											trade_name: e,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Status">
							<Select
								value={getFilterValue(filters, "status")}
								onChange={(e) => {
									setFilters((state) => {
										return {
											...state,
											status: e,
										};
									});
								}}
								placeholder={"Status"}
							>
								<Select.Option value="">Qualquer</Select.Option>
								<Select.Option value="0">Expirado</Select.Option>
								<Select.Option value="1">Ativo</Select.Option>
								<Select.Option value="2">
									Não cadastrou/requisitou empresa
								</Select.Option>
								<Select.Option value="3">Aguardando Abertura</Select.Option>
								<Select.Option value="4">Inativo</Select.Option>
								<Select.Option value="5">Cancelado</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item label="Status da Empresa">
							<Select
								value={getFilterValue(filters, "business_status") ?? ""}
								onChange={(e) => {
									setFilters((state) => {
										return {
											...state,
											business_status: e,
										};
									});
								}}
								placeholder={"Status"}
							>
								<Select.Option key={`biz_status_any`} value={""}>
									Qualquer
								</Select.Option>
								{BusinessStatus().map((i, index) => {
									return (
										<Select.Option key={`biz_status_${index}`} value={i.value}>
											{i.label}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>

						<Button onClick={filter} type="primary">
							Filtrar
						</Button>

						{hasFilters() ? (
							<Button onClick={clearFilters} style={{ marginLeft: "10px" }}>
								Limpar
							</Button>
						) : null}
					</Form>
				</Drawer>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data.Users.users.length ? data.Users.users : []}
								columns={columns}
								loading={loadingPagination}
								pagination={{
									defaultCurrent: data.Users.currentPage,
									total: data.Users.total,
									showSizeChanger: false,
									defaultPageSize: 10,
								}}
								onChange={async (pagination) => {
									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
										filters,
									});
									setLoadingPagination(false);
								}}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}

					<Detail
						{...detail}
						close={() => {
							setDetail({
								visible: false,
							});
						}}
					/>
				</div>
			</Content>
		</Layout>
	);
}

export default Users;
