import React, { useState, useEffect, useReducer, useRef } from "react";
import * as Queries from "./Queries";
import { useLazyQuery, useMutation, gql } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

import MaskPrice from "functions/mask/price";
import Mask from "functions/mask";
import PartnerSearch from "components/PartnerSearch";
import debounce from "functions/debounce";
import BusinessStatus from "scenes/users/util/BusinessStatus";
import {
	EditOutlined,
	MailOutlined,
	LockOutlined,
	MessageOutlined,
} from "@ant-design/icons";

import MaskedInput from "antd-mask-input";
import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Select,
	AutoComplete,
	Form,
	Input,
	Popconfirm,
	notification,
	Popover,
	Tooltip,
	Drawer,
	Badge,
} from "antd";
import { statuses } from "./Misc";
import Import from "./components/Import";

import { useDebounce } from "@react-hook/debounce";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const AUTOCOMPLETE_QUERY = gql`
	query BusinessAutocomplete($term: String!, $type: String!) {
		BusinessAutocomplete(term: $term, type: $type) {
			_id,
			company_name,
			trade_name
		}
	}
`;

function Users(props) {
	const mounted = useRef(false);
	const [resendId, setResendId] = useState(null);
	const [welcomeEmail, setWelcomeEmail] = useState(null);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const [drawer, setDrawer] = useState(false);
	const [loadingPagination, setLoadingPagination] = useState(false);

	// AUTOCOMPLETE

	const [loadCompanyName, companyName] = useLazyQuery(AUTOCOMPLETE_QUERY, {
		fetchPolicy: "no-cache",
	});
	const [companyNameDebounce, setCompanyNameDebounce] = useDebounce(null, 500);
	useEffect(() => {
		if (mounted.current) {
			loadCompanyName({
				variables: {
					term: companyNameDebounce,
					type: "company_name",
				},
			});
		}
	}, [companyNameDebounce]);

	const [loadTradeName, tradeName] = useLazyQuery(AUTOCOMPLETE_QUERY, {
		fetchPolicy: "no-cache",
	});
	const [tradenameDebounce, setTradeNameDebounce] = useDebounce(null, 500);
	useEffect(() => {
		if (mounted.current) {
			loadTradeName({
				variables: {
					term: tradenameDebounce,
					type: "trade_name",
				},
			});
		}
	}, [tradenameDebounce]);

	const [resetUserPass, { loading: loadingUserPass, data: dataUserPass }] =
		useMutation(Queries.USER_RESET_PASSWORD);
	const [
		welcomeEmailSend,
		{ loading: loadingWelcomeEmail, data: welcomeEmailData },
	] = useMutation(Queries.USER_WELCOME_EMAIL);

	const [load, { loading, data, error, refetch, called }] = useLazyQuery(
		Queries.USERS,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [exportAction, exportData] = useLazyQuery(gql`
		 query UsersExport($filters: UserListFilters) {
    		UsersExport(filters: $filters)
		 }
	`, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if(data?.UsersExport) {
				window.open(data.UsersExport);
			} 
		},
		onError: (err) => {
			notification.error({
				message: "Não foi possível exportar usuários no momento"
			})
		}
	});

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);

	const [filters, setFilters] = useState({
		status: "1",
		business_status: urlParams.get("business_status")
			? urlParams.get("business_status")
			: "",
	});

	const [pages, setPages] = useState({
		current: 1,
	});

	useEffect(() => {
		filter();
		mounted.current = true;
	}, []);

	useEffect(() => {
		if (dataUserPass) {
			if (dataUserPass?.UserResetPassword) {
				setResendId(null);
				notification.success({
					message: "E-mail de redefinição de senha enviado com sucesso!",
				});
			} else {
				notification.error({
					message: "Erro",
					description: "Não foi possível redefinir a senha no momento.",
				});
			}
		}
		if (welcomeEmailData) {
			if (welcomeEmailData?.UserResendWelcomeEmail) {
				setWelcomeEmail(false);
				notification.success({
					message: "E-mail enviado com sucesso!",
				});
			} else {
				notification.error({
					message: "Erro",
					description: "Não foi possível enviar o e-mail no momento.",
				});
			}
		}
	}, [dataUserPass, welcomeEmailData]);

	const filter = () => {
		setPages({ current: 1 });
		load({
			variables: {
				filters,
				page: 1,
			},
		});
	};

	const hasFilters = () => {
		let has = false;
		for (var prop in filters) {
			if (prop !== "status") {
				if (filters[prop] || filters[prop] !== "") has = true;
			}
		}
		return has;
	};

	const clearFilters = () => {
		setPages({ current: 1 });
		setFilters({
			status: "1",
		});
		forceUpdate();
		load({
			variables: {
				page: 1,
			},
		});
	};

	const getFilterValue = (filters, path) => {
		return filters[path] || null;
	};

	const columns = [
		{
			title: "Nome",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (email) => email || "-",
		},
		{
			title: "CNPJs",
			dataIndex: "cnpj",
			key: "cnpj",
			render: (cnpj) => (
				<Popover
					content={
						<span>
							{cnpj.map((i) => (
								<span>
									{i}
									<br />
								</span>
							))}
						</span>
					}
					title="CNPJs"
				>
					{cnpj.length ? (
						<span>
							{cnpj[0]} {cnpj.length > 1 ? `e mais ${cnpj.length - 1}...` : ""}
						</span>
					) : (
						"-"
					)}
				</Popover>
			),
		},
		{
			title: "Empresa",
			dataIndex: "company",
			key: "company",
			render: (value, row) => row?.partner_company?.name || "-",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status) => {
				return statuses[status];
			},
		},
		{
			title: "Ações",
			dataIndex: "",
			key: "x",
			width: 190,
			render: (value, row) => {
				return (
					<div>
						<Tooltip title="Editar">
							<Button
								shape="circle"
								icon={<EditOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									props.history.push(`/user/${row._id}`);
								}}
							/>
						</Tooltip>

						<Tooltip title="Atendimento">
							<Button
								shape="circle"
								icon={<MessageOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									props.history.push(`/user/logs/${row._id}`);
								}}
							/>
						</Tooltip>

						{row?.cnpj?.length ? (
							<>
								<Tooltip title="Reenviar E-mail">
									<Popconfirm
										title="Reenviar e-mail de boas-vindas?"
										okText="Sim"
										cancelText="Não"
										visible={welcomeEmail === row._id ? true : false}
										onCancel={() => setWelcomeEmail(null)}
										okButtonProps={{ loading: loadingWelcomeEmail }}
										onConfirm={() => {
											welcomeEmailSend({
												variables: {
													id: row._id,
												},
											});
										}}
									>
										<Button
											shape="circle"
											icon={<MailOutlined />}
											style={{ marginRight: 8 }}
											onClick={() => setWelcomeEmail(row._id)}
										/>
									</Popconfirm>
								</Tooltip>

								<Tooltip title="Redefinir Senha">
									<Popconfirm
										title="Deseja redefinir a senha?"
										okText="Sim"
										cancelText="Não"
										visible={resendId === row._id ? true : false}
										onCancel={() => setResendId(null)}
										okButtonProps={{ loading: loadingUserPass }}
										onConfirm={() => {
											resetUserPass({
												variables: {
													id: row._id,
												},
											});
										}}
									>
										<Button
											shape="circle"
											icon={<LockOutlined />}
											onClick={() => setResendId(row._id)}
										/>
									</Popconfirm>
								</Tooltip>
							</>
						) : null}
					</div>
				);
			},
		},
	];

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Clientes</Title>

					<div style={{ display: "flex" }}>
						<Popconfirm
							placement="top"
							title={"Tem certeza que deseja exportar usuários?"}
							onConfirm={() => {
								exportAction({
									variables: {
										filters,
									},
								});
							}}
							okText="Sim"
							cancelText="Não"
						>
							<Button
								style={{ marginRight: 10 }}
								loading={exportData.loading}
								disabled={exportData.loading}
							>
								Exportar
							</Button>
						</Popconfirm>

						<Import />
						{hasFilters() ? (
							<Badge dot>
								{" "}
								<Button
									style={{ marginLeft: 10 }}
									onClick={() => {
										setDrawer(true);
									}}
								>
									Filtros
								</Button>
							</Badge>
						) : (
							<Button
								style={{ marginLeft: 10 }}
								onClick={() => {
									setDrawer(true);
								}}
							>
								Filtros
							</Button>
						)}

						<Button
							type="primary"
							style={{ marginLeft: 10 }}
							onClick={() => {
								props.history.push("/create-user");
							}}
						>
							Novo Cliente
						</Button>
					</div>
				</div>

				<Drawer
					title="Filtros"
					visible={drawer}
					onClose={() => setDrawer(false)}
					width={600}
				>
					<Form layout="vertical">
						<Form.Item label="Nome">
							<Input
								value={getFilterValue(filters, "name")}
								onChange={(e) => {
									const { value } = e.target;
									setFilters((state) => {
										return {
											...state,
											name: value,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="CPF">
							<MaskedInput
								mask={"111.111.111-11"}
								value={getFilterValue(filters, "cpf")}
								onChange={(e) => {
									const { value } = e.target;
									setFilters((state) => {
										return {
											...state,
											cpf: value,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="CNPJ">
							<MaskedInput
								mask={"11.111.111/1111-11"}
								value={getFilterValue(filters, "cnpj")}
								onChange={(e) => {
									const { value } = e.target;
									setFilters((state) => {
										return {
											...state,
											cnpj: value,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="E-mail">
							<Input
								value={getFilterValue(filters, "email")}
								onChange={(e) => {
									const { value } = e.target;
									setFilters((state) => {
										return {
											...state,
											email: value,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Razão Social">
							<AutoComplete
								options={
									companyName?.data?.BusinessAutocomplete?.length
										? companyName?.data?.BusinessAutocomplete.map((i) => {
												return {
													label: i.company_name,
													value: i.company_name,
												};
										  })
										: []
								}
								allowClear
								onClear={() => {
									setFilters((state) => {
										return {
											...state,
											company_name: null,
										};
									});
								}}
								value={getFilterValue(filters, "company_name")}
								onSearch={(e) => {
									setCompanyNameDebounce(e);
								}}
								onChange={(e) => {
									setFilters((state) => {
										return {
											...state,
											company_name: e,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Nome Fantasia">
							<AutoComplete
								options={
									tradeName?.data?.BusinessAutocomplete?.length
										? tradeName?.data?.BusinessAutocomplete.map((i) => {
												return {
													label: i.trade_name,
													value: i.trade_name,
												};
										  })
										: []
								}
								allowClear
								onClear={() => {
									setFilters((state) => {
										return {
											...state,
											trade_name: null,
										};
									});
								}}
								value={getFilterValue(filters, "trade_name")}
								onSearch={(e) => {
									setTradeNameDebounce(e);
								}}
								onChange={(e) => {
									setFilters((state) => {
										return {
											...state,
											trade_name: e,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Status">
							<Select
								value={getFilterValue(filters, "status")}
								onChange={(e) => {
									setFilters((state) => {
										return {
											...state,
											status: e,
										};
									});
								}}
								placeholder={"Status"}
							>
								<Select.Option value="">Qualquer</Select.Option>
								<Select.Option value="0">Expirado</Select.Option>
								<Select.Option value="1">Ativo</Select.Option>
								<Select.Option value="2">
									Não cadastrou/requisitou empresa
								</Select.Option>
								<Select.Option value="3">Aguardando Abertura</Select.Option>
								<Select.Option value="4">Inativo</Select.Option>
								<Select.Option value="5">Cancelado</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item label="Status da Empresa">
							<Select
								value={getFilterValue(filters, "business_status") ?? ""}
								onChange={(e) => {
									setFilters((state) => {
										return {
											...state,
											business_status: e,
										};
									});
								}}
								placeholder={"Status"}
							>
								<Select.Option key={`biz_status_any`} value={""}>
									Qualquer
								</Select.Option>
								{BusinessStatus().map((i, index) => {
									return (
										<Select.Option key={`biz_status_${index}`} value={i.value}>
											{i.label}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					

						<Button onClick={filter} type="primary">
							Filtrar
						</Button>

						{hasFilters() ? (
							<Button onClick={clearFilters} style={{ marginLeft: "10px" }}>
								Limpar
							</Button>
						) : null}
					</Form>
				</Drawer>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data.Users.users.length ? data.Users.users : []}
								columns={columns}
								loading={loadingPagination}
								pagination={{
									defaultCurrent: data.Users.currentPage,
									total: data.Users.total,
									showSizeChanger: false,
									defaultPageSize: 10,
								}}
								onChange={async (pagination) => {
									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
										filters,
									});
									setLoadingPagination(false);
								}}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>
		</Layout>
	);
}

export default Users;
