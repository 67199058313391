import React, { useState } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import BusinessStatus from "scenes/users/util/BusinessStatus";
import {
	Card,
	Col,
	Row,
	Layout,
	Typography,
	Divider,
	Button,
	Statistic,
} from "antd";
import { Link } from "react-router-dom";

import { ArrowUpOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;

function Dashboard() {
	const { loading, data, error, refetch } = useQuery(Queries.DASHBOARD, {
		fetchPolicy: "no-cache",
	});

	const getBusinessStatus = (id) => {
		let status = BusinessStatus();
		let filter = status.find((i) => i.value === id);
		if (filter) return filter.label;
		return "Pendente";
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div className="dashboard-view">
							<Row
								gutter={30}
								style={{
									marginBottom: 40,
								}}
							>
								<Col span={12}>
									<Title>Novos Clientes</Title>

									<Row gutter={30}>
										<Col span={8}>
											<Card>
												<Statistic title="30 dias" value={12} />
											</Card>
										</Col>
										<Col span={8}>
											<Card>
												<Statistic title="60 dias" value={20} />
											</Card>
										</Col>
										<Col span={8}>
											<Card>
												<Statistic title="90 dias" value={36} />
											</Card>
										</Col>
									</Row>
								</Col>
								<Col span={12}>
									<Title>Inadimplentes</Title>

									<Row gutter={30}>
										<Col span={8}>
											<Card>
												<Statistic
													title="30 dias"
													value={3}
													valueStyle={{ color: "#3f8600" }}
													prefix={<ArrowUpOutlined />}
												/>
											</Card>
										</Col>
										<Col span={8}>
											<Card>
												<Statistic title="60 dias" value={5} />
											</Card>
										</Col>
										<Col span={8}>
											<Card>
												<Statistic title="90 dias" value={12} />
											</Card>
										</Col>
									</Row>
								</Col>
							</Row>

							<Title>Tickets</Title>

							<Row gutter={16}>
								<Col span={6}>
									<Card
										size="small"
										title={<div className="danger">Aguardando</div>}
									>
										<h4>{data.Dashboard?.tickets?.awaiting || "0"}</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										size="small"
										title={<div className="info">Em progresso</div>}
									>
										<h4>{data.Dashboard?.tickets?.in_progress || "0"}</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										size="small"
										title={<div className="solved">Resolvido</div>}
									>
										<h4>{data.Dashboard?.tickets?.solved || "0"}</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card size="small" title="Aguardando Cliente">
										<h4>{data.Dashboard?.tickets?.waiting_customer || "0"}</h4>
									</Card>
								</Col>
							</Row>

							{/* <Title>CNPJs Abertos</Title>

							<Row gutter={16}>
							<Col span={4}>
									<Card>
										<Statistic title={"Dezembro/2023"} value={312.660} />
										<br/>
										<Button>Consultar</Button>
									</Card>
								</Col>
								<Col span={4}>
									<Card>
										<Statistic title={"Janeiro/2024"} value={352.448} />
										<br/>
										<Button>Consultar</Button>
									</Card>
								</Col>
								<Col span={4}>
									<Card>
										<Statistic title={"Fevereiro/2024"} value={337.118} />
										<br/>
										<Button>Consultar</Button>
									</Card>
								</Col>
								<Col span={4}>
									<Card>
										<Statistic title={"Março/2024"} value={342.403} />
										<br/>
										<Button>Consultar</Button>
									</Card>
								</Col>
								<Col span={4}>
									<Card>
										<Statistic title={"Abril/2024"} value={385.307} />
										<br/>
										<Button>Consultar</Button>
									</Card>
								</Col>
								<Col span={4}>
									<Card>
										<Statistic title={"Maio/2024"} value={"347.120"} />
										<br/>
										<Button>Consultar</Button>
									</Card>
								</Col>
								
							</Row> */}
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>
		</Layout>
	);
}

export default Dashboard;
